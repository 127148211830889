import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";
import { AddCurrentPageAndLimitProps, HandleSearchProps, StoreAllocationListData, StoreAllocationListDataProps } from "./interface";



export const addCurrentPageAndLimit = ({ data, limitPerPage, currentPage}: AddCurrentPageAndLimitProps): StoreAllocationListDataProps => {
    const startIndex = (currentPage - 1) * limitPerPage;
    const endIndex = startIndex + limitPerPage;
    const paginatedData = data?.slice(startIndex, endIndex);
    const modifiedData = {
        data: paginatedData,
        totalPages: Math.ceil(data?.length / limitPerPage),
        count: data?.length,
        currentPage: currentPage,
    };

    return modifiedData;
};


export const handleSearch = ({ value, data, setCurrentPage, setLimitPerPage, setStoreAllocationList,setAllocationPartialData }: HandleSearchProps) => {
    let modifiedData: StoreAllocationListDataProps
    setCurrentPage(Default_Page_Number);
    setLimitPerPage(Default_Page_Limit);
    if (!value) {
        modifiedData = addCurrentPageAndLimit({
            data: data,
            limitPerPage: Default_Page_Limit,
            currentPage: Default_Page_Number,
        });
        setAllocationPartialData(data)
        setStoreAllocationList(modifiedData);

        return;
    }

    let filterData:StoreAllocationListData[] = data?.filter((item) =>
        item?.store_location?.toLowerCase()?.includes(value.toLowerCase())
    );

    if (filterData.length <= 0) {
        filterData = data?.filter((item) =>
            item?.brands?.some((brand) =>
                brand?.brand_name?.toLowerCase()?.includes(value?.toLowerCase())
            )
        );
    }

    modifiedData = addCurrentPageAndLimit({
        data: filterData,
        limitPerPage: Default_Page_Limit,
        currentPage: Default_Page_Number,
    });
    setAllocationPartialData(filterData)
    setStoreAllocationList(modifiedData);

};

