import { useEffect, useRef, useState } from "react";
import atoms from "../../../atoms";
import molecules from "../../../molecules";
import CopyIcon from "../../../../assets/Images/copy-icon.svg";
import CloseIcon from "../../../../assets/Images/close.svg";
import { Store_Legal_Status } from "../../../../constants/defaultValues";
import constant from "../../../../constants/constant";
import "./Store.scss";
import { useParams } from "react-router";
import service from "../../../../api/services";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { City_List, State_List } from "../../../../constants/state_cities";
const { Input, Image, Select, Button, RadioInput, DatePicker } = atoms;
const { CreateModal } = molecules;
const AddStore = ({
  step,
  setStoreDetails,
  storeDetails,
  imagesURL,
  bannerImagesURL,
  errors,
  handleChangePassword,
  handleEmailError,
  errorMessage,
  setErrors,
  updateStoreLocation,
}: any) => {
  const inputFileRef = useRef<any>();
  const inputStoreFileRef = useRef<any>();

  const [previewImage, setPreviewImage] = useState<any>([]);
  const [previewStoreBanner, setPreviewStoreBanner] = useState<any>([]);

  const [createModal, setCreateModal] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [userIdError, setUserIdError] = useState<string>("");
  // const [storeLocationError, setStoreLocationError] = useState<string>('')
  const [cities, setCities] = useState<any>([]);
  const [limitError, setLimitError] = useState<any>({});
  const debounce: any = useRef(null);

  const params = useParams();
  const _handleImageChange = (event: any) => {
    const files = event.target.files;
    const updatedImages: any = [...storeDetails.store_images];
    const previewImageUrl: any = [...previewImage];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const imageUrl = URL.createObjectURL(file);
      previewImageUrl.push(imageUrl);
      updatedImages.push(file);
    }
    setPreviewImage(previewImageUrl);
    setStoreDetails({ ...storeDetails, store_images: updatedImages });
  };

  // for website Image upload
  const _handleStoreBannerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const updatedImages: File[] = [...storeDetails.store_banner];
    const previewImageUrl: string[] = [...previewStoreBanner];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const imageUrl = URL.createObjectURL(file);
      previewImageUrl.push(imageUrl);
      updatedImages.push(file);
    }

    setPreviewStoreBanner(previewImageUrl);
    setStoreDetails({ ...storeDetails, store_banner: updatedImages });
  };

  const _chooseBannerToUpload = () => {
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const _chooseStoreBannerToUpload = () => {
    if (inputStoreFileRef && inputStoreFileRef.current) {
      inputStoreFileRef.current.click();
    }
  };

  const deleteImage = (image_ID: string, index: number) => {
    let tempPreview = [...previewImage];

    tempPreview.splice(index, 1);
    setPreviewImage(tempPreview);
    const updatedImages = storeDetails?.store_images;
    updatedImages.splice(index, 1);
    setStoreDetails({ ...storeDetails, store_images: updatedImages });
    imagesDelete(image_ID);
  };

  // for store banner
  const deleteStoreBanner = (image_ID: string, index: number) => {
    let tempPreview = [...previewStoreBanner];

    tempPreview.splice(index, 1);
    setPreviewStoreBanner(tempPreview);
    const updatedImages = storeDetails?.store_banner;
    updatedImages.splice(index, 1);
    setStoreDetails({ ...storeDetails, store_banner: updatedImages });
    imagesDelete(image_ID);
  };

  const _handelCurrentCover = (current: number) => {
    setStoreDetails({ ...storeDetails, cover_index: current });
  };
  useEffect(() => {
    setPreviewImage(imagesURL);
    setPreviewStoreBanner(bannerImagesURL);
  }, [imagesURL, bannerImagesURL]);

  const imagesDelete = async (url: string) => {
    try {
      let payload = {
        url: url,
        store_id: params?.id,
      };
      await service.store.deleteStoreImages(payload);
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const getStoreId = () => {
    let tempString = storeDetails.pin_code.toString();
    let extractedDigits = tempString.slice(0, 3);
    let paddedCount = storeDetails.store_count
      ?.toString()
      ?.padStart(3, "0")
      ?.slice(0, 3);
    let completeStoreID = [extractedDigits, paddedCount].join("_");
    setStoreDetails({ ...storeDetails, store_id: completeStoreID });
  };

  useEffect(() => {
    getStoreId();
  }, [storeDetails.pin_code, storeDetails.store_count]);

  const changePassword = () => {
    handleChangePassword();
    setCreateModal(false);
  };

  const handleStateChange = (stateValue: any) => {
    setStoreDetails({ ...storeDetails, state: stateValue });
    setStoreDetails((prevState: any) => ({ ...prevState, city: "" }));
    if (stateValue) {
      const filteredCities = City_List.filter(
        (city) => city.stateID === stateValue.stateID
      );
      setCities(filteredCities);
    }
  };
  const handleWareHouse = (name: string) => {
    if (storeDetails[name]) {
      setStoreDetails({ ...storeDetails, [name]: false });
    } else {
      setStoreDetails({ ...storeDetails, [name]: true });
    }
  };

  const _handleSearchUserID = async (userID: string) => {
    setStoreDetails({
      ...storeDetails,
      admin_username: userID,
      admin_name: "",
      admin_password: "",
    });
    try {
      const response = await service.store.searchUser(userID);
      if (response.status === 200) {
        const login_id = response?.data?.data?.login_id;
        const admin_name = response?.data?.data?.username;
        if (login_id) {
          setUserIdError("Warning !!! Existing User");
          setStoreDetails({
            ...storeDetails,
            admin_username: login_id,
            admin_name: admin_name,
            admin_password: "******",
          });
        }
      }
    } catch (error: any) {
      if (error.response.status == 404) {
        setUserIdError("");
      }
    }
  };

  const searchStoreLocationAPI = async (store_location: string) => {
    const payload = {
      location: store_location,
    };
    try {
      const response = await service.store.searchStoreLocation(payload);
      if (response.status === 200) {
        setErrors((previous: any) => ({ ...previous, store_location: "" }));
      }
    } catch (error: any) {
      if (error?.response.status === 400) {
        setErrors({ store_location: "Warning !!! Existing Store Location" });
      }
      if (error.response.status == 404) {
        setUserIdError("");
      }
    }
  };

  const _handleSearchStoreLocation = async (store_location: string) => {
    setStoreDetails({
      ...storeDetails,
      store_location: store_location,
    });

    if (updateStoreLocation?.trim() != store_location?.trim()) {
      if (debounce.current) {
        clearTimeout(debounce.current);
      }

      debounce.current = window.setTimeout(() => {
        if (store_location) searchStoreLocationAPI(store_location);
      }, 1000);
    } else {
      setErrors((previous: any) => ({ ...previous, store_location: "" }));
    }
  };

  useEffect(() => {
    if (storeDetails.owner_is_manager === "true") {
      const updatedStoreDetails = {
        ...storeDetails,
        manager_name: storeDetails.owner_name,
        manager_mobile: storeDetails.owner_mobile,
        manager_email: storeDetails.owner_email,
      };
      handleEmailError(updatedStoreDetails.owner_email, "manager_email");
      handleEmailError(updatedStoreDetails.owner_email, "owner_email");
      setStoreDetails(updatedStoreDetails);
    } else {
      if (!params?.id) {
        const updatedStoreDetails = {
          ...storeDetails,
          manager_name: "",
          manager_mobile: "",
          manager_email: "",
        };
        handleEmailError(updatedStoreDetails.owner_email, "manager_email");
        handleEmailError(updatedStoreDetails.owner_email, "owner_email");
        setStoreDetails(updatedStoreDetails);
      }
    }
  }, [
    storeDetails.owner_is_manager,
    storeDetails.owner_name,
    storeDetails.owner_mobile,
    storeDetails.owner_email,
  ]);

  return (
    <>
      {/* ======= Step 1 : Basic Details ======= */}
      {step === 0 && (
        <div id="step1">
          {/* {params?.id && */}
          <div className=" d-flex justify-content-between gap-3 align-items-center mb-4">
            <div>
              <h2 className="mb-0 fs-18 fw-600 secondary-color ">Store ID:-</h2>
              <div className="d-flex cursor-pointer justify-content-between px-3 align-items-center py-2 add-product-custom-div">
                <h2 className="mb-0 fs-16 fw-600 secondary-color">
                  {storeDetails.store_id}
                </h2>
                <div className="">
                  <Image
                    src={CopyIcon}
                    onClick={() =>
                      navigator?.clipboard?.writeText(storeDetails.store_id)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex">
              {/* <div className='col-6'>
                            <label className='d-flex cursor-pointer px-3 align-items-center py-2 add-product-custom-div' htmlFor='stock_ownership' style={{ cursor: 'pointer', minWidth:"15rem" }}>
                                <input
                                    type='checkbox'
                                    id='stock_ownership'
                                    value={storeDetails?.stock_ownership}
                                    checked={storeDetails?.stock_ownership}
                                    onChange={()=>handleWareHouse("stock_ownership")}
                                />
                                <h2 className='mb-0 fs-18 fw-600 secondary-color px-3'>Stock Ownership</h2>
                            </label>

                        </div> */}
              <div className="col-6">
                <label
                  className="d-flex cursor-pointer px-3 align-items-center py-2 add-product-custom-div"
                  htmlFor="warehouseCheckbox"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    id="warehouseCheckbox"
                    value={storeDetails?.is_warehouse}
                    checked={storeDetails?.is_warehouse}
                    onChange={() => handleWareHouse("is_warehouse")}
                  />
                  <h2 className="mb-0 fs-18 fw-600 secondary-color px-3">
                    Warehouse
                  </h2>
                </label>
              </div>
            </div>
          </div>
          {/* } */}
          <form>
            <div className="row">
              {/* ========== Store Details ======= */}
              <div className="col-4">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter Store Name"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      store_name: e.target.value,
                    })
                  }
                  value={storeDetails?.store_name}
                  label="Store Name"
                />
                {errors.store_name && !storeDetails.store_name && (
                  <span className="error-text">{errors.store_name}</span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter Contact Number"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      store_contact_number: e.target.value.replace(
                        /[^0-9\-+]/g,
                        ""
                      ),
                    })
                  }
                  value={storeDetails.store_contact_number}
                  label="Store Contact Number"
                />
                {errors.store_contact_number &&
                  !storeDetails.store_contact_number && (
                    <span className="error-text">
                      {errors.store_contact_number}
                    </span>
                  )}
              </div>
              <div className="col-4">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter Store Address"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      store_address: e.target.value,
                    })
                  }
                  value={storeDetails.store_address}
                  label="Store Address"
                />
                {errors.store_address && !storeDetails.store_address && (
                  <span className="error-text">{errors.store_address}</span>
                )}
              </div>
            </div>

            <div className="row mt-25">
              <div className="col-4">
                <Select
                  label="State"
                  options={State_List}
                  className={"w-100"}
                  placeholder={"Select State"}
                  onChange={(item: any) => {
                    handleStateChange(item);
                  }}
                  value={storeDetails.state}
                />
                {errors.state && !storeDetails.state && (
                  <span className="error-text">{errors.state}</span>
                )}
              </div>
              <div className="col-4">
                <Select
                  label="City"
                  options={cities}
                  className={"w-100"}
                  placeholder={"Select City"}
                  disabled={!cities}
                  onChange={(item: any) =>
                    setStoreDetails({ ...storeDetails, city: item })
                  }
                  value={storeDetails.city}
                />
                {errors.city && !storeDetails.city && (
                  <span className="error-text">{errors.city}</span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="number"
                  className="form-control"
                  placeholder="Enter Pin Code"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      pin_code: e.target.value,
                    })
                  }
                  value={storeDetails.pin_code}
                  label="Pin Code"
                />
                {errors.pin_code && !storeDetails.pin_code && (
                  <span className="error-text">{errors.pin_code}</span>
                )}
              </div>
            </div>

            {/* ========== Store Details ======= */}
            {/* <div className="row mt-25">
                        <div className="col-4">
                            <Input type="text" className="form-control" 
                            placeholder="Enter Store Location" 
                            onChange={(e: any) => setStoreDetails({ ...storeDetails, store_location: e.target.value })} value={storeDetails.store_location} label='Store Location'
                            />
                            {(errors.store_location && !storeDetails.store_location) && <span className="error-text">{errors.store_location}</span>}
                        </div>
                    </div> */}
            <div className="row mt-25">
              <div className="col-4">
                <div className="position-relative">
                  <label className="inputs-label mb-2 fs-16 fw-600  secondary-color">
                    Store Name and Location
                  </label>
                  <input
                    type="text"
                    className={"inputs form-control w-100"}
                    placeholder={"Enter Store Name and Location"}
                    value={storeDetails.store_location}
                    onChange={(e: any) =>
                      _handleSearchStoreLocation(e.target.value)
                    }
                  // disabled={params?.id ? true : false}
                  />
                </div>
                {(errors.store_location || !storeDetails.store_location) && (
                  <span className="error-text">{errors.store_location}</span>
                )}
              </div>
              <div className="col-4">
                <div className="position-relative">
                  <label className="inputs-label mb-2 fs-16 fw-600  secondary-color">
                    Store Code
                  </label>
                  <input
                    type="text"
                    className={"inputs form-control w-100"}
                    placeholder={"Enter Store Code"}
                    value={storeDetails.store_code}
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        store_code: e.target.value,
                      })
                    }
                  // disabled={params?.id ? true : false}
                  />
                </div>
                {(errors.store_location || !storeDetails.store_location) && (
                  <span className="error-text">{errors.store_location}</span>
                )}
              </div>
              <div className="col-4">
                <div className="position-relative">
                  <DatePicker
                    label={"At your Service Since"}
                    placeholder={"Select"}
                    selectedYear={Number(storeDetails?.at_your_service_since)}
                    setSelectedYear={(value: number) => setStoreDetails({
                      ...storeDetails,
                      at_your_service_since: value,
                    })}
                  />
                </div>
                {(errors.store_location || !storeDetails.store_location) && (
                  <span className="error-text">{errors.store_location}</span>
                )}
              </div>
            </div>
            <div className="row mt-25">
              <div className="col-4">
                <div className="position-relative">
                  <label className="inputs-label mb-2 fs-16 fw-600  secondary-color">
                    Store Google Rating
                  </label>
                  <input
                    type="number"
                    className={"inputs form-control w-100"}
                    placeholder={"Enter Store Google Rating"}
                    value={storeDetails.google_rating}
                    onChange={(e: any) => e.target.value <= 5 &&
                      setStoreDetails({
                        ...storeDetails,
                        google_rating: e.target.value,
                      })
                    }
                  // disabled={params?.id ? true : false}
                  />
                </div>
                {(errors.store_location || !storeDetails.store_location) && (
                  <span className="error-text">{errors.store_location}</span>
                )}
              </div>
              <div className="col-4">
                <div className="position-relative">
                  <label className="inputs-label mb-2 fs-16 fw-600  secondary-color">
                    Online Store Commission (%)
                  </label>
                  <input
                    type="text"
                    className={"inputs form-control w-100"}
                    placeholder={"Enter Online Store Commission (%)"}
                    value={storeDetails.online_commission_percent}
                    onChange={(e:  React.ChangeEvent<HTMLInputElement>) => (!isNaN(Number(e.target.value)) && Number(e.target.value) <= 100) &&
                      setStoreDetails({
                        ...storeDetails,
                        online_commission_percent: Number(e.target.value),
                      })
                    }
                  />
                </div>
                {(errors.online_commission_percent || !storeDetails.online_commission_percent) && (
                  <span className="error-text">{errors.online_commission_percent}</span>
                )}
              </div>
              <div className="col-4"></div>

            </div>
            {/* ========== Uploaded Images ================= */}
            <div className="row">
              <div className="col">
                <label className="inputs-label mt-25 mb-2 fs-16 fw-600  secondary-color">
                  Uploaded Images
                </label>
              </div>
              <div className="d-flex flex-wrap gap-3  upload_main">
                <div className="upload_container">
                  <div className="m-0">
                    <div className="d-none">
                      <input type="file" id="imageUpload" accept="image/*" />
                    </div>
                    <div
                      className="upload_image_wrapper cursor-pointer"
                      onClick={() => _chooseBannerToUpload()}
                    >
                      <div
                        id="imagePreview"
                        className="align-items-center d-flex flex-column justify-content-center upload_image_container"
                      >
                        <Button
                          className="upload_btn all-color m-4 p-1"
                          onClick={(e) => e.preventDefault()}
                        >
                          Upload
                        </Button>
                        <p className="fs-10 fw-400">
                          formate :- JPG, JPGE, PNG
                        </p>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    ref={inputFileRef}
                    accept="image/*"
                    onChange={(e) => _handleImageChange(e)}
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
                {previewImage &&
                  previewImage.map((image: string, index: number) => {
                    return (
                      <>
                        <div
                          className="photo_upload"
                          onClick={() => _handelCurrentCover(index)}
                        >
                          <div className="photo_preview cursor-pointer mb-2">
                            <div id="imagePreview">
                              <Image
                                src={`${image && image}`}
                                alt="aaa"
                                className={"img-fluid preview_image"}
                              />
                            </div>
                            <div
                              className="align-items-center d-flex delete-icon justify-content-center"
                              onClick={() => {
                                deleteImage(image, index);
                              }}
                            >
                              <Image src={CloseIcon} />
                            </div>
                            {storeDetails.cover_index === index && (
                              <div className="align-items-center bottom-0 position-absolute text-center w-100 cover-image">
                                <p className="cover-text fs-12 fw-500 my-3">
                                  Cover Image
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              {errors.store_images && storeDetails.store_images.length <= 0 && (
                <span className="error-text mt-1">{errors.store_images}</span>
              )}
            </div>

            {/* ========== Store Owner Details ================= */}
            <div className="row mt-25">
              <div className="col-4">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter Owner Name"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      owner_name: e.target.value,
                    })
                  }
                  value={storeDetails.owner_name}
                  label="Store Owner Name"
                />
                {errors.owner_name && !storeDetails.owner_name && (
                  <span className="error-text">{errors.owner_name}</span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="number"
                  className="form-control"
                  placeholder="Enter Contact Number"
                  onChange={(e: any) => {
                    (e.target.value.length <= 10 || e.target.value === "") &&
                      setStoreDetails({
                        ...storeDetails,
                        owner_mobile: e.target.value,
                      });
                  }}
                  value={storeDetails.owner_mobile}
                  label="Owner Contact Number"
                  maxLength={10}
                />
                {errors.owner_mobile && !storeDetails.owner_mobile && (
                  <span className="error-text">{errors.owner_mobile}</span>
                )}
              </div>
              {/* =======================owner email================== */}
              <div className="col-4">
                <Input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email Address"
                  onChange={(e: any) => {
                    setStoreDetails({
                      ...storeDetails,
                      owner_email: e.target.value,
                    }),
                      handleEmailError(e.target.value, "owner_email");
                  }}
                  value={storeDetails.owner_email}
                  label="Owner Email Address"
                />
                {errorMessage.owner_email && (
                  <span className="error-text">
                    {errorMessage?.owner_email}
                  </span>
                )}
              </div>
            </div>
            <div className="row mt-25">
              <div className="col">
                <label className="inputs-label mb-1 fs-16 fw-600  secondary-color">
                  Store Owner Is Also Store Incharge
                </label>
                <div className="d-flex gap-3">
                  <RadioInput
                    type="radio"
                    name="owner_is_manager"
                    className="ms-2 form-check-input"
                    onChange={(e: any) => {
                      setStoreDetails({
                        ...storeDetails,
                        owner_is_manager: e.target.value,
                      }),
                        handleEmailError(
                          storeDetails.owner_email,
                          "manager_email"
                        );
                    }}
                    checked={storeDetails.owner_is_manager === "true"}
                    value={"true"}
                    label="Yes"
                    id={"Yes"}
                  />
                  <RadioInput
                    type="radio"
                    name="owner_is_manager"
                    className="ms-2 form-check-input"
                    onChange={(e: any) => {
                      setStoreDetails({
                        ...storeDetails,
                        owner_is_manager: e.target.value,
                      }),
                        handleEmailError(
                          storeDetails.manager_email,
                          "manager_email"
                        );
                    }}
                    checked={storeDetails.owner_is_manager === "false"}
                    value={"false"}
                    label="No"
                    id={"No"}
                  />
                </div>
                {errors.owner_is_manager && !storeDetails.owner_is_manager && (
                  <span className="error-text">{errors.owner_is_manager}</span>
                )}
              </div>
            </div>
            {/* =========== Store Incharge Details ================= */}
            {
              <div className="row mt-25">
                <div className="col-4">
                  <Input
                    type="text"
                    disabled={
                      storeDetails.owner_is_manager === "false" ? false : true
                    }
                    className="form-control"
                    placeholder="Enter Incharge Name"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        manager_name: e.target.value,
                      })
                    }
                    value={storeDetails.manager_name}
                    label="Store Incharge Name"
                  />
                  {errors.manager_name && !storeDetails.manager_name && (
                    <span className="error-text">{errors.manager_name}</span>
                  )}
                </div>
                <div className="col-4">
                  <Input
                    type="number"
                    className="form-control"
                    disabled={
                      storeDetails.owner_is_manager === "false" ? false : true
                    }
                    placeholder="Enter Contact Number"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        manager_mobile: e.target.value,
                      })
                    }
                    value={storeDetails.manager_mobile}
                    label="Incharge Contact Number"
                  />
                  {errors.manager_mobile && !storeDetails.manager_mobile && (
                    <span className="error-text">{errors.manager_mobile}</span>
                  )}
                </div>
                <div className="col-4">
                  <Input
                    type="email"
                    className="form-control"
                    disabled={
                      storeDetails.owner_is_manager === "false" ? false : true
                    }
                    placeholder="Enter Email Address"
                    onChange={(e: any) => {
                      setStoreDetails({
                        ...storeDetails,
                        manager_email: e.target.value,
                      }),
                        handleEmailError(e.target.value, "manager_email");
                    }}
                    value={storeDetails.manager_email}
                    label="Incharge Email Address"
                  />
                  {errorMessage.manager_email && (
                    <span className="error-text">
                      {errorMessage?.manager_email}
                    </span>
                  )}
                </div>
              </div>
            }
            {/* ============ For Store Website Status ============ */}
            <div className="row mt-25">
              <div className="inputs-label mb-3 fs-20 fw-600  secondary-color">
                Website Detail :-
              </div>
              <div className="col-4">
                <Input
                  type="time"
                  className="form-control"
                  placeholder="Enter store Opening hour"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      opening_time: e.target.value,
                    })
                  }
                  value={storeDetails.opening_time}
                  label="Store Open Time"
                />
                {errors.opening_time && !storeDetails.opening_time && (
                  <span className="error-text">{errors.opening_time}</span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="time"
                  className="form-control"
                  placeholder="Enter store Closing hour"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      closing_time: e.target.value,
                    })
                  }
                  value={storeDetails.closing_time}
                  label="Store Close Time"
                />
                {errors.closing_time &&
                  storeDetails.closing_time < storeDetails.opening_time && (
                    <span className="error-text">{errors.closing_time}</span>
                  )}
              </div>
              <div className="col-4">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter Store Location Latitude"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      latitude: e.target.value,
                    })
                  }
                  value={storeDetails.latitude}
                  label="Latitude"
                />
                {errors.latitude && (
                  <span className="error-text">{errors.latitude}</span>
                )}
              </div>
            </div>
            <div className="row mt-25">
              <div className="col-4">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter Store Location Longitude"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      longitude: e.target.value,
                    })
                  }
                  value={storeDetails.longitude}
                  label="Longitude"
                />
                {errors.longitude && (
                  <span className="error-text">{errors.longitude}</span>
                )}
              </div>
            </div>
            {/* ========== Uploaded Images ================= */}
            <div className="row">
              <div className="col">
                <label className="inputs-label mt-25 mb-2 fs-16 fw-600  secondary-color">
                  Uploaded Store Banner
                </label>
              </div>
              <div className="d-flex flex-wrap gap-3  upload_main">
                <div className="upload_container">
                  <div className="m-0">
                    <div className="d-none">
                      <input type="file" id="imageUpload" accept="image/*" />
                    </div>
                    <div
                      className="upload_image_wrapper cursor-pointer"
                      onClick={() => _chooseStoreBannerToUpload()}
                    >
                      <div
                        id="imagePreview"
                        className="align-items-center d-flex flex-column justify-content-center upload_image_container"
                      >
                        <Button
                          className="upload_btn all-color m-4 p-1"
                          onClick={(e) => e.preventDefault()}
                        >
                          Upload
                        </Button>
                        <p className="fs-10 fw-400">
                          formate :- JPG, JPGE, PNG
                        </p>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    ref={inputStoreFileRef}
                    accept="image/*"
                    onChange={(e) => _handleStoreBannerChange(e)}
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
                {previewStoreBanner && previewStoreBanner?.length > 0 &&
                  previewStoreBanner.map((imageURL: string, index: number) => (
                    <>
                      <div className="photo_upload">
                        <div className="photo_preview cursor-pointer mb-2">
                          <div id="imagePreview" key={index}>
                            <Image
                              src={`${imageURL && imageURL}`}
                              alt="aaa"
                              className={"img-fluid preview_image"}
                            />
                          </div>
                          <div
                            className="align-items-center d-flex delete-icon justify-content-center"
                            onClick={() => {
                              deleteStoreBanner(imageURL, index);
                            }}
                          >
                            <Image src={CloseIcon} />
                          </div>
                        </div>
                      </div>
                    </>
                  ))

                }
              </div>
              {errors.store_images && storeDetails.store_images.length <= 0 && (
                <span className="error-text mt-1">{errors.store_images}</span>
              )}
            </div>

            {/* ============ Legal Status ============ */}
            <div className="row mt-25">
              <div className="inputs-label mb-3 fs-20 fw-600  secondary-color">
                Legal Status :-
              </div>
              <div className="col-4">
                <Select
                  label="Store Legal Status"
                  options={Store_Legal_Status}
                  className={"w-100"}
                  placeholder={"Select Status"}
                  onChange={(item: any) =>
                    setStoreDetails({ ...storeDetails, legal_status: item })
                  }
                  value={storeDetails.legal_status}
                />
                {errors.legal_status && !storeDetails.legal_status && (
                  <span className="error-text">{errors.legal_status}</span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="number"
                  className="form-control"
                  placeholder="Enter TDS Percentage"
                  onChange={(e: any) =>
                    setStoreDetails({ ...storeDetails, tds: e.target.value })
                  }
                  value={storeDetails.tds}
                  label="TDS (%)"
                />
                {errors.tds && !storeDetails.tds && (
                  <span className="error-text">{errors.tds}</span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="number"
                  className="form-control"
                  placeholder="Enter Credit Percentage"
                  onChange={(e: any) =>
                    setStoreDetails({ ...storeDetails, credit: e.target.value })
                  }
                  value={storeDetails.credit}
                  label="Credit Amount (₹)"
                />
                {errors.credit && !storeDetails.credit && (
                  <span className="error-text">{errors.credit}</span>
                )}
              </div>
            </div>
            {/* ================ Bank Details ================== */}
            <div className="row mt-25">
              <div className="inputs-label mb-3 fs-20 fw-600  secondary-color">
                Bank Account Details :-
              </div>
              <div className="col-4">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter Bank Name"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      bank_name: e.target.value,
                    })
                  }
                  value={storeDetails.bank_name}
                  label="Bank Name"
                />
                {errors.bank_name && !storeDetails.bank_name && (
                  <span className="error-text">{errors.bank_name}</span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter IFSC Code"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      ifsc_code: e.target.value,
                    })
                  }
                  value={storeDetails.ifsc_code}
                  label="IFSC Code"
                />
                {errors.ifsc_code && !storeDetails && (
                  <span className="error-text">{errors.ifsc_code}</span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="number"
                  className="form-control"
                  placeholder="Enter Account Number"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      account_number: e.target.value,
                    })
                  }
                  value={storeDetails.account_number}
                  label="Account Number"
                />
                {errors.account_number && !storeDetails.account_number && (
                  <span className="error-text">{errors.account_number}</span>
                )}
              </div>
            </div>
          </form>
        </div>
      )}

      {/* ======= Step 2 : profile Details======= */}

      {step === 1 && (
        <div id="step2">
          <form>
            <div className="row mt-25">
              <div className="col-4">
                <Input
                  type="number"
                  className="form-control"
                  placeholder="Enter Staff Count"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      total_staffs: e.target.value,
                    })
                  }
                  value={storeDetails.total_staffs}
                  label="Total Team Members"
                />
                {errors.total_staffs && !storeDetails.total_staffs && (
                  <span className="error-text">{errors.total_staffs}</span>
                )}
              </div>
              <div className="col-4">
                <Select
                  label="Type of Furniture"
                  options={constant.STORE_FURNITURE_TYPE}
                  className={"w-100"}
                  placeholder={"Select type"}
                  onChange={(item: any) =>
                    setStoreDetails({ ...storeDetails, furniture_type: item })
                  }
                  value={storeDetails.furniture_type}
                />
                {errors.furniture_type && !storeDetails.furniture_type && (
                  <span className="error-text">{errors.furniture_type}</span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="number"
                  className="form-control"
                  placeholder="Enter Charges"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      additional_charge: e.target.value,
                    })
                  }
                  value={storeDetails.additional_charge}
                  label="Additional Charges"
                />
                {errors.additional_charge &&
                  !storeDetails.additional_charge && (
                    <span className="error-text">
                      {errors.additional_charge}
                    </span>
                  )}
              </div>
            </div>
            <div className="row mt-25">
              <div className="col-4">
                <label className="inputs-label mb-1 fs-16 fw-600  secondary-color">
                  Chair Unit Available
                </label>
                <div className="d-flex gap-3">
                  <RadioInput
                    type="radio"
                    name="chair_unit"
                    className="ms-2 form-check-input"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        chair_unit_available: e.target.value,
                      })
                    }
                    checked={storeDetails.chair_unit_available === "true"}
                    label="Yes"
                    value={"true"}
                    id={"chair_unit1"}
                  />
                  <RadioInput
                    type="radio"
                    name="chair_unit"
                    className="ms-2 form-check-input"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        chair_unit_available: e.target.value,
                      })
                    }
                    checked={storeDetails.chair_unit_available === "false"}
                    label="No"
                    value={"false"}
                    id={"chair_unit2"}
                  />
                </div>
                {errors.chair_unit_available &&
                  !storeDetails.chair_unit_available && (
                    <span className="error-text">
                      {errors.chair_unit_available}
                    </span>
                  )}
              </div>
              <div className="col-4">
                <label className="inputs-label mb-1 fs-16 fw-600  secondary-color">
                  AR Machine Available
                </label>
                <div className="d-flex gap-3">
                  <RadioInput
                    type="radio"
                    name="ar_machine"
                    className="ms-2 form-check-input"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        ar_machine_available: e.target.value,
                      })
                    }
                    checked={storeDetails.ar_machine_available === "true"}
                    label="Yes"
                    value={"true"}
                    id={"ar_machine1"}
                  />
                  <RadioInput
                    type="radio"
                    name="ar_machine"
                    className="ms-2 form-check-input"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        ar_machine_available: e.target.value,
                      })
                    }
                    checked={storeDetails.ar_machine_available === "false"}
                    label="No"
                    value={"false"}
                    id={"ar_machine2"}
                  />
                </div>
                {errors.ar_machine_available &&
                  !storeDetails.ar_machine_available && (
                    <span className="error-text">
                      {errors.ar_machine_available}
                    </span>
                  )}
              </div>
              <div className="col-4">
                <label className="inputs-label mb-1 fs-16 fw-600  secondary-color">
                  Lensometer Available
                </label>
                <div className="d-flex gap-3">
                  <RadioInput
                    type="radio"
                    name="lensometer_available"
                    className="ms-2 form-check-input"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        lensometer_available: e.target.value,
                      })
                    }
                    checked={storeDetails.lensometer_available === "true"}
                    label="Yes"
                    value={"true"}
                    id={"lensometer_available1"}
                  />
                  <RadioInput
                    type="radio"
                    name="lensometer_available"
                    className="ms-2 form-check-input"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        lensometer_available: e.target.value,
                      })
                    }
                    checked={storeDetails.lensometer_available === "false"}
                    label="No"
                    value={"false"}
                    id={"lensometer_available2"}
                  />
                </div>
                {errors.lensometer_available &&
                  !storeDetails.lensometer_available && (
                    <span className="error-text">
                      {errors.lensometer_available}
                    </span>
                  )}
              </div>
            </div>
            <div className="row mt-25">
              <div className="col-4">
                <label className="inputs-label mb-1 fs-16 fw-600  secondary-color">
                  Slit Lamp
                </label>
                <div className="d-flex gap-3">
                  <RadioInput
                    type="radio"
                    name="slit_lamp"
                    className="ms-2 form-check-input "
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        slit_lamp: e.target.value,
                      })
                    }
                    checked={storeDetails.slit_lamp === "true"}
                    label="Yes"
                    value={"true"}
                    id={"slit_lamp1"}
                  />
                  <RadioInput
                    type="radio"
                    name="slit_lamp"
                    className="ms-2 form-check-input"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        slit_lamp: e.target.value,
                      })
                    }
                    checked={storeDetails.slit_lamp === "false"}
                    label="No"
                    value={"false"}
                    id={"slit_lamp2"}
                  />
                </div>
                {errors.slit_lamp && !storeDetails.slit_lamp && (
                  <span className="error-text">{errors.slit_lamp}</span>
                )}
              </div>
              <div className="col-4">
                <label className="inputs-label mb-1 fs-16 fw-600  secondary-color">
                  Qualified Optometrist Available
                </label>
                <div className="d-flex gap-3">
                  <RadioInput
                    type="radio"
                    name="qualified_optometrist"
                    className="ms-2 form-check-input cursor-pointer"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        optometrist_available: e.target.value,
                      })
                    }
                    checked={storeDetails.optometrist_available === "true"}
                    label="Yes"
                    value={"true"}
                    id={"qualified_optometrist1"}
                  />
                  <RadioInput
                    type="radio"
                    name="qualified_optometrist"
                    className="ms-2 form-check-input cursor-pointer"
                    onChange={(e: any) =>
                      setStoreDetails({
                        ...storeDetails,
                        optometrist_available: e.target.value,
                      })
                    }
                    checked={storeDetails.optometrist_available === "false"}
                    label="No"
                    value={"false"}
                    id={"qualified_optometrist2"}
                  />
                </div>
                {errors.optometrist_available &&
                  !storeDetails.optometrist_available && (
                    <span className="error-text">
                      {errors.optometrist_available}
                    </span>
                  )}
              </div>
            </div>
            <div className="row mt-25">
              <div className="row">
                <div className="inputs-label mb-3 fs-20 fw-600  secondary-color col-6">
                  Store Admin Login Details :-
                </div>
                {params.id && (
                  <div className="col-6 text-end">
                    <span
                      onClick={() => setCreateModal(true)}
                      className="add-btn"
                    >
                      Change Password
                    </span>
                  </div>
                )}
              </div>

              <div className="col-4">
                <div className="position-relative">
                  <label className="inputs-label mb-2 fs-16 fw-600  secondary-color">
                    User ID
                  </label>
                  <input
                    type="text"
                    className={"inputs form-control w-100"}
                    placeholder={"Enter User ID"}
                    value={storeDetails.admin_username}
                    onChange={(e: any) => _handleSearchUserID(e.target.value)}
                  // disabled={params?.id ? true : false}
                  />
                </div>
                {(errors.admin_username || !storeDetails.admin_username) && (
                  <span className="error-text">{errors.admin_username}</span>
                )}
                {userIdError && (
                  <span className="fs-12" style={{ color: "#ff6200" }}>
                    {userIdError}
                  </span>
                )}
              </div>
              <div className="col-4">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter User Name"
                  onChange={(e: any) => {
                    setStoreDetails({
                      ...storeDetails,
                      admin_name: e.target.value,
                    });
                    // handleEmailError(e.target.value, "admin_email")
                  }}
                  value={storeDetails.admin_name}
                  label="User Name"
                />
                {errors.admin_name && (
                  <span className="error-text">{errors.admin_name}</span>
                )}
              </div>
              <div className="col-4 position-relative">
                <Input
                  type={`${showPassword || params?.id ? "text" : "password"}`}
                  className="form-control "
                  placeholder="Enter Password"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      admin_password: e.target.value,
                    })
                  }
                  value={
                    params?.id || userIdError
                      ? "********"
                      : storeDetails.admin_password
                  }
                  disabled={params?.id || userIdError}
                  label="Password"
                />
                {!params?.id && (
                  <span
                    className="position-absolute cursor-pointer"
                    style={{ top: "45%", right: "10%" }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </span>
                )}
                {!params?.id &&
                  errors.admin_password &&
                  !storeDetails.admin_password && (
                    <span className="error-text">{errors.admin_password}</span>
                  )}
              </div>
            </div>
          </form>
        </div>
      )}

      {/* ======= Step 3 : Documents ======= */}

      {step === 2 && (
        <div id="step3">
          <div className="row mt-25">
            <div className="col-12">
              <label className="inputs-label mb-1 fs-16 fw-600  secondary-color">
                Provide GST Details
              </label>
              <div className="d-flex gap-3 mt-3">
                <RadioInput
                  type="radio"
                  name="gst_type"
                  className="ms-2 form-check-input"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      gst_type: constant.GST_DETAILS.REGULAR,
                    })
                  }
                  label="REGULAR"
                  checked={
                    storeDetails.gst_type === constant.GST_DETAILS.REGULAR &&
                    true
                  }
                  id={"gst_type"}
                />
                <RadioInput
                  type="radio"
                  name="composition"
                  className="ms-2 form-check-input"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      gst_type: constant.GST_DETAILS.COMPOSITION,
                    })
                  }
                  label="COMPOSITION"
                  checked={
                    storeDetails.gst_type ===
                    constant.GST_DETAILS.COMPOSITION && true
                  }
                  id={"composition"}
                />
                <RadioInput
                  type="radio"
                  name="na"
                  className="ms-2 form-check-input"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      gst_type: constant.GST_DETAILS.NA,
                    })
                  }
                  label="NA"
                  checked={
                    storeDetails.gst_type === constant.GST_DETAILS.NA && true
                  }
                  id={"na"}
                />
              </div>
            </div>
          </div>
          {storeDetails.gst_type !== constant.GST_DETAILS.NA && (
            <div className="row mt-25 align-items-center">
              <div className="col-4 mt-3">
                <label className="inputs-label fs-16 fw-600  secondary-color ">
                  GST Number :
                </label>
              </div>
              <div className="col-4 mt-3">
                <Input
                  type="text"
                  placeholder="Enter GST Number"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      gst_number: e.target.value,
                    })
                  }
                  value={storeDetails.gst_number}
                />
                {errors.gst_number && !storeDetails.gst_number && (
                  <span className="error-text">{errors.gst_number}</span>
                )}
              </div>
              <div className="col-4 mt-2">
                <Input
                  type="file"
                  className="px-4 py-3"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      gst_document: e.target.files[0],
                    })
                  }
                  label="Upload Documents"
                />
                <p className="fs-12 fw-400 mb-0 secondary-color mt-1">
                  Formate :- JPG, JPEG, PNG, PDF
                </p>
                {errors.gst_document && !storeDetails.gst_document && (
                  <span className="error-text">{errors.gst_document}</span>
                )}
              </div>
            </div>
          )}
          <div className="row mt-25 align-items-center">
            <div className="col-4 mt-3">
              <label className="inputs-label fs-16 fw-600  secondary-color ">
                Company PAN :
              </label>
            </div>
            <div className="col-4 mt-3">
              <Input
                type="text"
                placeholder="Enter Company PAN"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    pan_number: e.target.value,
                  })
                }
                value={storeDetails.pan_number}
              />
              {errors.pan_number && !storeDetails.pan_number && (
                <span className="error-text">{errors.pan_number}</span>
              )}
            </div>
            <div className="col-4 mt-2">
              <Input
                type="file"
                className="px-4 py-3"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    pan_document: e.target.files[0],
                  })
                }
                label="Upload Documents"
              />
              <p className="fs-12 fw-400 secondary-color mb-0 mt-1">
                Formate :- JPG, JPEG, PNG, PDF
              </p>
              {errors.pan_document && !storeDetails.pan_document && (
                <span className="error-text">{errors.pan_document}</span>
              )}
            </div>
          </div>
          <div className="row mt-25 align-items-center">
            <div className="col-4 mt-3">
              <label className="inputs-label fs-16 fw-600  secondary-color mb-0 ">
                Gumasta License :
              </label>
            </div>
            <div className="col-4 mt-3">
              <Input
                type="text"
                placeholder="Enter License Number"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    gumasta_number: e.target.value,
                  })
                }
                value={storeDetails.gumasta_number}
                label=""
              />
              {errors.gumasta_number && !storeDetails.gumasta_number && (
                <span className="error-text">{errors.gumasta_number}</span>
              )}
            </div>
            <div className="col-4 mt-2">
              <Input
                type="file"
                className="px-4 py-3"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    gumasta_document: e.target.files[0],
                  })
                }
                label="Upload Documents"
              />
              <p className="fs-12 fw-400 secondary-color mb-0 mt-1">
                Formate :- JPG, JPEG, PNG, PDF
              </p>
              {errors.gumasta_document && !storeDetails.gumasta_document && (
                <span className="error-text">{errors.gumasta_document}</span>
              )}
            </div>
          </div>
          <div className="row mt-25 align-items-center">
            <div className="col-4 mt-3">
              <label className="inputs-label fs-16 fw-600  secondary-color mb-0 ">
                Nagar Nigam License :
              </label>
            </div>
            <div className="col-4 mt-3">
              <Input
                type="text"
                placeholder="Enter License Number"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    nagar_nigam_number: e.target.value,
                  })
                }
                value={storeDetails.nagar_nigam_number}
              />
              {errors.nagar_nigam_number &&
                !storeDetails.nagar_nigam_number && (
                  <span className="error-text">
                    {errors.nagar_nigam_number}
                  </span>
                )}
            </div>
            <div className="col-4 mt-2">
              <Input
                type="file"
                className="px-4 py-3"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    nagar_nigam_document: e.target.files[0],
                  })
                }
                label="Upload Documents"
              />
              <p className="fs-12 fw-400 secondary-color mb-0 mt-1">
                Formate :- JPG, JPEG, PNG, PDF
              </p>
              {errors.nagar_nigam_document &&
                !storeDetails.nagar_nigam_document && (
                  <span className="error-text">
                    {errors.nagar_nigam_document}
                  </span>
                )}
            </div>
          </div>
          <div className="row mt-25 align-items-center">
            <div className="col-4 mt-3">
              <label className="inputs-label mb-0 fs-16 fw-600  secondary-color ">
                Incorporation Details :
              </label>
            </div>
            <div className="col-4 mt-3">
              <Input
                type="text"
                placeholder="Enter Incorporation Details"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    incorporation_number: e.target.value,
                  })
                }
                value={storeDetails.incorporation_number}
              />
              {errors.incorporation_number &&
                !storeDetails.incorporation_number && (
                  <span className="error-text">
                    {errors.incorporation_number}
                  </span>
                )}
            </div>
            <div className="col-4 mt-2">
              <Input
                type="file"
                className="px-4 py-3"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    incorporation_document: e.target.files[0],
                  })
                }
                label="Upload Documents"
              />
              <p className="fs-12 fw-400 secondary-color mb-0 mt-1">
                Formate :- JPG, JPEG, PNG, PDF
              </p>
              {errors.incorporation_document &&
                !storeDetails.incorporation_document && (
                  <span className="error-text">
                    {errors.incorporation_document}
                  </span>
                )}
            </div>
          </div>
          <div className="row mt-25 align-items-center">
            <div className="col-4 mt-3">
              <label className="inputs-label mb-0 fs-16 fw-600  secondary-color mb-0 ">
                Agency Document :
              </label>
            </div>
            <div className="col-4 mt-3">
              <Input
                type="text"
                placeholder="Enter Agency Document"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    agency_number: e.target.value,
                  })
                }
                value={storeDetails.agency_number}
                label=""
              />
              {errors.agency_number && !storeDetails.agency_number && (
                <span className="error-text">{errors.agency_number}</span>
              )}
            </div>
            <div className="col-4 mt-2">
              <Input
                type="file"
                className="px-4 py-3"
                onChange={(e: any) =>
                  setStoreDetails({
                    ...storeDetails,
                    agency_document: e.target.files[0],
                  })
                }
                label="Upload Documents"
              />
              <p className="fs-12 fw-400 secondary-color mb-0 mt-1">
                Formate :- JPG, JPEG, PNG, PDF
              </p>
              {errors.agency_document && !storeDetails.agency_document && (
                <span className="error-text">{errors.agency_document}</span>
              )}
            </div>
          </div>
        </div>
      )}
      {createModal && (
        <CreateModal
          show={createModal}
          header={" New Password"}
          handleSubmit={() => changePassword()}
          submitBtnText={"Change Password"}
          onHide={() => setCreateModal(!createModal)}
        >
          <div>
            <form>
              <div className=" mt-25">
                <Input
                  type={`${showPassword ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Enter Password"
                  onChange={(e: any) =>
                    setStoreDetails({
                      ...storeDetails,
                      admin_change_password: e.target.value,
                    })
                  }
                  defaultValue={storeDetails.admin_change_password}
                  label="Password"
                />
                <span
                  className="position-absolute cursor-pointer"
                  style={{ top: "61%", right: "10%" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </form>
          </div>
        </CreateModal>
      )}
    </>
  );
};

export default AddStore;
