import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { Contact_lens, Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";
import { HeaderHeading } from '../../../../Context'
import { BASE_URL } from "../../../../constants/defaultValues"
import { getContactLensType, getParams, modifiedDataForContactLens } from "../../../../constants/utils";
import { useSearchParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const { PendingProduct } = organisms

const Index = () => {
    const [searchParams] = useSearchParams();
    const [productData, setProductData] = useState<any>({})
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [productDetail, setProductDetail] = useState({});
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [brand, setBrand] = useState<any>([])
    const [productType, setProductType] = useState<any>([])
    const [newProductData, setNewProductData] = useState<any>({})
    const [brandResponse, setBrandResponse] = useState([])
    const [typeValue, setTypeValue] = useState<any>(getParams('pending-type'))
    const [isContactLensProduct, setIsContactLensProduct] = useState<boolean>(getContactLensType())
    const [productId, setProductID] = useState<string>('')
    const { addToast } = useToasts();

    const getDropdown = (response: []) => {
        let dropdown: any = []
        response && response.map((value: any) => {
            dropdown.push({
                "value": value.id || value.product_type.id,
                "label": value.name || value.brand_name || value.color_name || value.product_type.name,
                "code": value?.brand_code || value?.product_type_initials || value?.product_type?.product_type_initials
            }
            )
        })
        return dropdown
    }

    const getProductData = async () => {
        try {
            let brandResponse = await service.productData.brandListingService({ brand_category: typeValue })
            let productTypeResponse = await service.productData.productTypeListingService({ search: '' });
            let modifiedData: any = modifiedDataForContactLens({ data: productTypeResponse.data.data, filterCondition: (item:any) => (item?.name)?.toLowerCase() !== Contact_lens })

            setBrand(getDropdown(brandResponse?.data?.data))
            setProductType(getDropdown(modifiedData))
            setBrandResponse(brandResponse?.data?.data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleDownload = async () => {
        const brands = newProductData?.brand_id?.map((item: any) => item?.value)
        const product_types = newProductData?.product_type_id?.map((item: any) => item?.value)
        const payload = {
            brands: brands,
            product_types: product_types,
            modal_number: newProductData?.modal_number,
            is_contact_lens: isContactLensProduct,
            brand_category: typeValue,
        }
        try {
            const response = await service.BulkEditService.download(payload)
            if (response.status === 200) {
                let path = response.data.data
                let fullPath = `${BASE_URL}${path}`
                let cleanPAth = fullPath.replace(/api\/\.\//, '')
                let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
                // window.open(`${cleanPAth}`, '_blank')

                setTimeout(() => {
                    fetch(`${cleanPAth}`).then((response) => {
                        response.blob().then((blob) => {

                            // Creating new object of PDF file
                            const fileURL =
                                window.URL.createObjectURL(blob);

                            // Setting various property values
                            let alink = document.createElement("a");
                            alink.href = fileURL;
                            alink.download = `${fileName}`;
                            alink.click();
                        });
                    });
                }, 2000);

            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAllDetail = async () => {
        const brands = newProductData?.brand_id?.map((item: any) => item?.value)
        const product_types = newProductData?.product_type_id?.map((item: any) => item?.value)
        let NewBrands = JSON.stringify(brands)
        let NewProductTypes = JSON.stringify(product_types)
        try {
            let response = await service.product.view(
                {
                    "page": currentPage,
                    "search": search,
                    "limit": limitPerPage,
                    "status": "",
                    "brands": NewBrands,
                    "product_types": NewProductTypes,
                    "modal_number": newProductData?.modal_number,
                    'brand_category': typeValue,
                    "is_contact_lens": isContactLensProduct
                }
            )
            if (response.status === 200) {
                setProductData(response.data)
                localStorage.removeItem('isContactLensProduct')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDetailById = async (id: string) => {
        try {
            let response = await service.product.getDetailById(id)
            if (response.status === 200)
                setProductDetail(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const updateVerificationStatus = async (id: string, status: number) => {
        try {
            let response = await service.product.updateStatusById(id, { "status": status });
            getAllDetail()
        } catch (error) {
            console.log(error)
        }
    }


    const handleProductDelete = async (id: string) => {
        try {
            let response = await service.product.productDelete(id);
            if (response.status === 200) {
                getAllDetail()
            }
        } catch (error) {
            console.log(error)
        }

    }

    const toggleProductActivation = async()=>{
        try {
            const response = await service.product.deactivateActiveProduct(productId)
            if (response.status === 200) {
                getAllDetail()
                setProductID('')
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    useEffect(() => {
        getProductData()
    }, [typeValue])


    useEffect(() => {

        setTypeValue(getParams('pending-type'))
        setProductData({})
        setBrand([])
        setProductType([])
        setBrandResponse([])
        setCurrentPage(Default_Page_Number)
        setLimitPerPage(Default_Page_Limit)
        setSearch('')
    }, [searchParams])


    useEffect(() => {
        const initialize = async () => {
            setHeader("Product - Pending");
            setSidebar(false);
            getAllDetail();
        };
        initialize();
    }, [currentPage, typeValue, limitPerPage, search, newProductData,isContactLensProduct])




    return (
        <Wrapper>
            <PendingProduct
                productResponse={productData}
                productDetail={productDetail && productDetail}
                updateVerificationStatus={(id: string, status: number) => { updateVerificationStatus(id, status) }}
                getDetailById={(id: string) => { getDetailById(id) }}
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                setSearch={setSearch} currentPage={currentPage} limit={limitPerPage}
                handleProductDelete={handleProductDelete}
                brand={brand}
                productType={productType}
                setProductData={setNewProductData}
                newProductData={newProductData}
                brandResponse={brandResponse}
                setProductType={setProductType}
                getDropdown={getDropdown}
                handleDownload={handleDownload}
                isContactLensProduct={isContactLensProduct}
                setIsContactLensProduct={(value: boolean) => setIsContactLensProduct(value)}
                toggleProductActivation={toggleProductActivation}
                setProductID={setProductID}
            />
        </Wrapper>
    )

}
export default Index