import React, { useEffect, useState, useContext } from "react";
import service from "../../../../api/services";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import {
    Default_Page_Limit,
    Default_Page_Number,
} from "../../../../constants/defaultValues";
import { listAllocationStore } from "../BrandAllocation/interface";
import { HeaderHeading } from "../../../../Context";
import { HandlePaginationActionProps, Params, StoreAllocationListData } from "./interface";
import { addCurrentPageAndLimit, handleSearch } from "./usePagination";
const { StoreAllocation } = organisms;
const Index = () => {
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [allocationAllData, setAllocationAllData] = useState<
        StoreAllocationListData[]
    >([]);
    const [allocationPartialData, setAllocationPartialData] = useState<
    StoreAllocationListData[]
>([]);
    const [storeAllocationList, setStoreAllocationList] = useState<
        listAllocationStore | any
    >();
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);

    const handlePaginationAction = ({
        data,
        limit,
        page,
    }:HandlePaginationActionProps) => {
        let params:Params = {
            data: data,
            limitPerPage: limit,
            currentPage: page
        };
        
        let modified_data = addCurrentPageAndLimit(params);
        setStoreAllocationList(modified_data);
        
    };

    const getStoreAllocationList = async () => {
        try {
            let response = await service.allocation.view();
            if (response.status === 200) {
                setAllocationAllData(response?.data?.data || []);
                setAllocationPartialData(response?.data?.data || []);
                handlePaginationAction({
                    data: response?.data?.data,
                    limit: limitPerPage,
                    page: currentPage
                })
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setHeader("Store - Allocated Brands");
        setSidebar(false);
        getStoreAllocationList();
    }, []);

    return (
        <Wrapper>
            <StoreAllocation
                setLimitPerPage={(limit: number) => {
                    setLimitPerPage(limit);
                    setCurrentPage(1);
                    handlePaginationAction({ data: allocationPartialData, limit: limit, page: 1 });
                }}
                setCurrentPage={(page: number) => {
                    setCurrentPage(page);
                    handlePaginationAction({ data: allocationPartialData, limit: limitPerPage, page: page });
                }}
                setSearch={(value: string) => handleSearch({
                    value: value,
                    data: allocationAllData,
                    setCurrentPage: setCurrentPage,
                    setLimitPerPage: setLimitPerPage,
                    setStoreAllocationList: setStoreAllocationList,
                    setAllocationPartialData:setAllocationPartialData
                })}
                storeAllocationList={storeAllocationList}
                currentPage={currentPage}
                limit={limitPerPage}
            />
        </Wrapper>
    );
};

export default Index;
