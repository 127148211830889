import { FRAME, SUNGLASS } from "../../../../constants/defaultValues"
import { ProductTypeDropdownProps, ProductTypeProps } from "../interface"

export const getProductTypeDropdown = (response: []):ProductTypeProps[] => {
    let dropdown: ProductTypeDropdownProps[] = []
    response && response?.map((value: any) => {
        if(value?.name.toLowerCase() === SUNGLASS || value?.name.toLowerCase() === FRAME){
            dropdown.push({
                "value": value.id,
                "label": value.name,
                "code":  value?.product_type?.product_type_initials
            }
            )
        }
    })
    return dropdown
}