import React, { useState, useEffect } from 'react'
import atoms from '../../../atoms'
import { CiFilter } from "react-icons/ci";
import moment from 'moment'
import constant from '../../../../constants/constant';
import { findConstantLabel, numberWithCommas, convertJsonToExcel } from '../../../../constants/utils'
import { Default_Page_Number } from '../../../../constants/defaultValues'
import molecules from '../../../molecules'
import { DisplayedDataProps } from './interface';
const { Input, Select, Button, Table, Pagination, CustomImages } = atoms
const { PriceRange, ContactLensContainer } = molecules
const Index = ({ storeData, brandData, searchdata, noDataText,
  setSearchdata, handleSubmit, listingData, range, handlePriceRange, handleInputPrice, setLimitPerPage, limit, isOpen, setIsOpen, isContactLensProduct, handleLensTypeChange }: any) => {

  // const [isOpen, setIsOpen] = useState(true);
  const [displayedData, setDisplayedData] = useState<DisplayedDataProps[]>([]);
  const columns = [
    { name: 'Barcode', selector: (row: any) => row?.bar_code, },
    { name: 'Brand Name', selector: (row: any) => row?.product?.brand?.brand_name ? row?.product?.brand?.brand_name : " ", },
    { name: 'Product Id', selector: (row: any) => row?.product?.product_id_display, },
    { name: 'Product Cover Photo', cell: (row: any) => <CustomImages src={row?.product?.images?.length > 0 && row?.product?.images[0]} />, center: true },
    { name: 'Model Number', selector: (row: any) => row?.product.modal_number, },
    { name: 'SRP', selector: (row: any) => `₹${numberWithCommas(parseFloat(row?.product.product_srp?.toFixed(2)))}`, },
    { name: 'Landing Cost', selector: (row: any) => `₹${numberWithCommas(parseFloat(row?.brand_cost_amount?.toFixed(2)))}` || '-', },
    { name: 'Ownership', selector: (row: any) => row?.store_ownership ? 'Store' : 'Specxweb', },
    { name: 'Store Name', selector: (row: any) => row?.store?.store_name, minWidth: "150px" },
    { name: 'Inventory Condition', selector: (row: any) => findConstantLabel(constant?.INVENTORY_ITEM_CONDITION, row?.item_condition) || '-', },
    { name: 'Created Date', selector: (row: any) => moment(row?.invoice.supplier_invoice_date).format('DD/MM/YYYY'), },
    // { name: 'Status', selector: (row: any) => row.status, },
    { name: "Status", selector: (row: any) => row.verification_status, },
  ];

  const handlechange = (name: string, value: any) => {
    setSearchdata({ ...searchdata, [name]: value })
  }
  const handleCloseFilter = () => {
    setIsOpen(!isOpen)
  }

  const handleDownloadExcel = () => {

    const excelData = listingData.map((item: any) => {
      const rowData: any = {};
      columns.forEach((column: any) => {
        if (column.name != "Product Cover Photo") {
          rowData[column.name] = column.selector(item);
        }
      });
      return rowData;
    });

    convertJsonToExcel(excelData, "Inventory_excel")
  }

  const [newCurrentPage, setNewCurrentPage] = useState(Default_Page_Number);
  const [pageIndex, setPageIndex] = useState({
    tableStartIndex: 0,
    tableEndIndex: 0
  })
  // const itemsPerPage = limit;
  const totalPages = Math.ceil(listingData?.length / limit);
  useEffect(() => {
    const startIndex = (newCurrentPage - 1) * limit;
    const endIndex = startIndex + limit;
    const slicedData = listingData?.slice(startIndex, endIndex);
    setPageIndex({ ...pageIndex, tableStartIndex: startIndex, tableEndIndex: endIndex })
    setDisplayedData(slicedData)
  }, [newCurrentPage, listingData, limit]);

  useEffect(() => {
    setNewCurrentPage(1)
  }, [limit])

  const handlePageChange = (newPage: any) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setNewCurrentPage(newPage);
    }
  };

  const mystyle = {
    height: "45px",
    margin: 0,
    background: isOpen && "#05B7E4",
    color: isOpen && "#fff"
  };

  return (
    <>
      <div className='col-12 d-flex justify-content-end'>
        <div className="d-flex mb-2">
          <ContactLensContainer
            value={isContactLensProduct}
            onStatusChange={(value: boolean) => { handleLensTypeChange(value) }}
          />
        </div>
        <Button className='d-flex align-items-center border button-transparent rounded' style={mystyle} onClick={handleCloseFilter}>
          <div className='fs-18 me-2 '>
            <CiFilter className='fw-600' />
          </div>
          <div className='fs-16 fw-400'>Filter</div>
        </Button>
        <Button name='Download Excel' onClick={handleDownloadExcel} style={mystyle} className='mx-3' />
      </div>

      {isOpen &&
        <div className='p-3 mt-3' style={{ background: "transparent", border: "1px solid #BFBFBF30" }}>

          <div className="row">
            <div className="col-3">
              <Select className={"w-100"} options={storeData} value={searchdata.store}
                onChange={(e: any) => handlechange("store", e)} placeholder={"Select"}
                label={"Select Stores"}
                isMulti={true} /*errors={errors.store_name}*/ />
            </div>
            <div className="col-3">
              <Input type={"text"} name="barcode" onChange={(e: any) => handlechange("barcode", e.target.value.trim())}
                value={searchdata.barcode} placeholder={"Enter Barcode"} label={"Barcode"} /*errors={errors.barcode}*/ />
            </div>
            <div className="col-3">
              <Input type={"text"} name="product_id" onChange={(e: any) => handlechange("product_id", e.target.value.trim())}
                value={searchdata.product_id} placeholder={"Enter Product Id"} label={"Product Id"} /*errors={'brandError?.brand_name'} */ />
            </div>
            <div className="col-3">
              <Input type={"text"} name="modal_number" onChange={(e: any) => handlechange("modal_number", e.target.value.trim())}
                value={searchdata.modal_number} placeholder={"Enter Model No"} label={"Model No"} /*errors={'brandError?.brand_name'} */ />
            </div>
            {/* <div className="col-3">
              <PriceRange range={range} onChange={handlePriceRange} handleInputPrice={handleInputPrice} />
            </div> */}
          </div>
          <div className="row mt-3">

            <div className="col-3">
              <Select className={"w-100"} options={brandData} value={searchdata.brand}
                onChange={(e: any) => handlechange("brand", e)} placeholder={"Select"}
                label={"Select Brand"}
                isMulti={true}
          /*errors={'errors?.product_type_id'}*/ />
            </div>

            <div className="col-3">
              <PriceRange range={range} onChange={handlePriceRange} handleInputPrice={handleInputPrice} />
            </div>
            <div className="col-3">
              <div className='row'>
                <div className='col-6'>
                  <Input className='light-placeholder' type={"Date"} name="created_from" onChange={(e: any) => handlechange("created_from", e.target.value)}
                    value={searchdata.created_from} placeholder={"Enter Start Date"} label={"Start Date"} /*errors={errors.barcode}*/ />
                </div>
                <div className='col-6'>
                  <Input className='light-placeholder' type={"Date"} name="created_to" onChange={(e: any) => handlechange("created_to", e.target.value)}
                    value={searchdata.created_to} placeholder={"Enter End Date"} label={"End Date"} /*errors={errors.barcode}*/ />
                </div>
              </div>
            </div>
            <div className='col-3 mt-4 d-flex justify-content-end'>
              <Button name='Search'
                className='mt-3 mb-0'
                isDisabled={!searchdata?.modal_number && (!searchdata?.created_from || !searchdata?.created_to) && !searchdata.barcode && !(searchdata?.store.length > 0) && !searchdata?.product_id && (!range.maxPrice || !range.minPrice) && !(searchdata?.brand.length > 0)}
                type={"submit"} onClick={() => handleSubmit()} />
            </div>
            {/* <Input type={"text"} name="price" onChange={(e: any) => handlechange("price", e.target.value)}
            value={searchdata.price} placeholder={"Enter Price"} label={"Price"} /> */}

          </div>
          {/* <Button name='Download Excel' /> */}
          {/* <div className="row mt-4">
            <div className="d-flex justify-content-between">
              <Button name='Search'
                isDisabled={!searchdata?.modal_number && !searchdata.barcode && !(searchdata?.store.length > 0) && !searchdata?.product_id && (!range.maxPrice || !range.minPrice) && !(searchdata?.brand.length > 0)}
                type={"submit"} onClick={() => handleSubmit()} />
            </div>
          </div> */}

        </div>}
      <div className="row">
        <div className="col">
          {noDataText ? <Table
            columns={columns}
            AdditionalClassName="add-invertory-table data-table-container"
            data={displayedData?.map((item, index: number) => {
              return ({
                ...item,
                verification_status:
                  item?.product?.verification_status ===
                    constant.VERIFICATION_STATUS.PENDING ? (
                    <div className="background-light-yellow border-product-radius">
                      <p className="color-yellow m-1 p-1">{"Pending"}</p>
                    </div>
                  ) : item?.product?.verification_status ===
                    constant.VERIFICATION_STATUS.VERIFIED ? (
                    <div className="background-light-green border-product-radius">
                      <p className="color-green m-1 p-1">{"Verified"}</p>
                    </div>
                  ) : item?.product?.verification_status ===
                    constant.VERIFICATION_STATUS.REWORK ? (
                    <div className="background-light-red border-product-radius">
                      <p className="color-red m-1 p-1">{"Rejected"}</p>
                    </div>
                  ) : (
                    ""
                  ),
                status: item.barcode_status === constant.VERIFICATION_STATUS.PENDING ? (
                  <div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.barcode_status === constant.VERIFICATION_STATUS.VERIFIED ?
                  (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Approved"}</p></div>) : ''
              })
            })}
          /> : <div className='justify-content-center d-flex mt-5 '>Loading...</div>}
        </div>
        <div className={`${isOpen && 'position-relative'}`} style={{ top: "12px" }}>
          <Pagination
            totalCount={listingData?.length}
            currentPage={newCurrentPage}
            totalPages={totalPages}
            onChangePage={handlePageChange}
            setLimit={(e: number) => setLimitPerPage(e)}
            limit={limit}
          />
        </div>
      </div>


    </>
  )
}

export default Index