import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import atoms from "../../../atoms";
import molecules from "../../../molecules";
import constant from "../../../../constants/constant";
import PlaceHolderImage from "../../../../assets/Images/placeholderimg.png";
import { ProductDetail, MyComponentProps } from "../interface";
import { FiEdit } from "react-icons/fi";
import { RiCheckboxCircleLine, RiCloseCircleLine, RiDeleteBin6Line } from "react-icons/ri";
import moment from "moment";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import ThreeDots from "../../../../assets/Images/threeDots.svg";
import {
    checkPermissionsInArray,
    numberWithCommas,
} from "../../../../constants/utils";
import { AllPermissions } from "../../../../constants/permissions";
const {
    Search,
    Button,
    Table,
    Image,
    Pagination,
    Select,
    Input,
    CustomImages,
} = atoms;
const { SidePopUp, ImagePopup, ConfirmationModal, ContactLensContainer } =
    molecules;
const Index: React.FC<MyComponentProps> = ({
    productResponse,
    setLimitPerPage,
    currentPage,
    setCurrentPage,
    setSearch,
    limit,
    productDetail,
    getDetailById,
    updateVerificationStatus,
    handleProductDelete,
    brand,
    productType,
    setProductData,
    brandResponse,
    setProductType,
    getDropdown,
    handleDownload,
    isContactLensProduct,
    setIsContactLensProduct,
    newProductData,
    setProductID,
    toggleProductActivation,
}) => {
    const navigate = useNavigate();
    const inputRef: any = useRef()
    const [showSidebarModal, setShowSidebarModal] = useState(false);
    const [showImageLarge, setShowImageLarge] = useState(false);
    const [toggleProductStatus, setToggleProductStatus] = useState<boolean>(false)
    const [imageUrl, setImageUrl] = useState();
    const [imageIndex, setImageIndex] = useState<any>();
    const [tableData, setTableData] = useState<any>();
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [subAdminId, setSubAdminId] = useState("");
    const [clickedBrandCategory, setClickedBrandCategory] = useState<any>();
    const urlParams = new URLSearchParams(location.search);
    const typeValue: any = urlParams?.get("pending-type");

    const columns = [
        {
            name: "Brand",
            selector: (row: ProductDetail) => row.brand.brand_name,
            sortable: true,
            minWidth: "15%",
        },
        {
            name: "Date",
            selector: (row: any) =>
                row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-",
        },
        {
            name: "Product ID",
            selector: (row: ProductDetail) => `${row.product_id_display}`,
            minWidth: "15%",
        },
        {
            name: "Cover Photo",
            cell: (row: any) => (
                <CustomImages src={row?.images?.length > 0 && row?.images[0]} />
            ),
            center: true,
        },
        {
            name: "Model No.",
            selector: (row: ProductDetail) => `${row.modal_number}`,
        },
        {
            name: "Product Type",
            selector: (row: ProductDetail) => row.product_type.name,
        },
        {
            name: "Product SRP (₹)",
            selector: (row: ProductDetail) =>
                `${row.product_srp !== null
                    ? `₹${numberWithCommas(row.product_srp)}`
                    : "-"
                }`,
        },
        { name: "Status", selector: (row: ProductDetail) => row.isActive },
        {
            name: "Verification Status",
            selector: (row: ProductDetail) => row.verification_status,
        },
        { name: "Action", selector: (row: any) => row.action },
    ];

    const columnsForContactLens = [
        {
            name: "Brand",
            selector: (row: ProductDetail) => row.brand.brand_name,
            sortable: true,
            minWidth: "15%",
        },
        {
            name: "Date",
            selector: (row: any) =>
                row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-",
        },
        {
            name: "Product ID",
            selector: (row: ProductDetail) => `${row.product_id_display}`,
            minWidth: "15%",
        },
        {
            name: "Cover Photo",
            cell: (row: any) => (
                <CustomImages src={row?.images?.length > 0 && row?.images[0]} />
            ),
            center: true,
        },
        {
            name: "Product Type",
            selector: (row: ProductDetail) => row.product_type.name,
        },
        {
            name: "Product SRP (₹)",
            selector: (row: ProductDetail) =>
                `${row.product_srp !== null
                    ? `₹${numberWithCommas(row.product_srp)}`
                    : "-"
                }`,
        },
        { name: "Status", selector: (row: ProductDetail) => row.isActive },
        {
            name: "Verification Status",
            selector: (row: ProductDetail) => row.verification_status,
        },
        { name: "Action", selector: (row: any) => row.action },
    ]

    const showImage = (imageUrl: any, index: any) => {
        setShowImageLarge((prevState) => !prevState);
        setImageUrl(imageUrl);
        setImageIndex(index);
    };

    const closeImage = () => {
        setShowImageLarge((prevState) => !prevState);
    };

    const handleChange = (name: string, value: any) => {
        setCurrentPage(1);
        setProductData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };
    // const handleBrand = (value: any) => {
    //     if (brandResponse && value) {
    //         setProductData((prev: any) => ({ ...prev, product_type_id: "" }));
    //         const filteredData: any = brandResponse?.filter((item: any) => item?.id === value?.value);
    //         setProductType(getDropdown(filteredData[0]?.product_types))
    //     }
    // }

    const handelDeleteButton = (id: string) => {
        setSubAdminId(id);
        setDeletePopup(!deletePopup);
    };

    useEffect(() => {
        if (productResponse?.data) {
            const result = productResponse?.data?.filter(
                (items: any) =>
                    items?.verification_status != constant.VERIFICATION_STATUS.VERIFIED
            );
            setTableData(result);
        }
    }, [productResponse]);

    const handleLensTypeChange = (value: boolean) => {
        setCurrentPage(1);
        setIsContactLensProduct(value)
        setSearch('')
        setProductData((prevState: any) => ({
            ...prevState,
            product_type_id: [],
            modal_number: '',
            brand_id: [],
        }));
    }
    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.value = "";
        }
    }, [isContactLensProduct]);
    return (
        <>
            <div className="d-flex mb-2">
                <ContactLensContainer
                    value={isContactLensProduct}
                    onStatusChange={(value: boolean) => { handleLensTypeChange(value) }}
                />
            </div>
            <div className="row">
                <div className="col-3">
                    <Search
                        className="w-100"
                        refs={inputRef}
                        searchCallBack={(e: string) => {
                            setCurrentPage(1);
                            // setIsContactLensProduct(false)
                            setSearch(e);
                        }}
                    />
                </div>
                <div className="col-6 mt-0">
                    <div className="row">
                        <div className="col-4 d-flex">
                            <Select
                                options={brand}
                                className={"w-100 product_multi_select"}
                                placeholder={"Select Brand"}
                                value={newProductData?.brand_id}
                                isMulti={true}
                                onChange={(e: any) => {
                                    handleChange("brand_id", e);
                                }}
                            />
                        </div>
                        {!isContactLensProduct && <div className="col-4 d-flex">
                            <Select
                                options={productType}
                                className={"w-100 product_multi_select"}
                                placeholder={"Select Type"}
                                isDisabled={isContactLensProduct}
                                isMulti={true}
                                onChange={(e: any) => {
                                    handleChange("product_type_id", e);
                                }}
                            />
                        </div>}
                        {!isContactLensProduct && <div className="col-4">
                            <Input
                                className="input-height"
                                type={"text"}
                                disabled={isContactLensProduct}
                                placeholder={"Enter Model Number"}
                                onChange={(e: any) => {
                                    handleChange("modal_number", e.target.value);
                                }}
                            />
                        </div>}
                    </div>
                </div>
                <div className="col-3 gap-1">
                    <div className="d-flex gap-2 justify-content-end">
                        {checkPermissionsInArray(
                            AllPermissions.Product_Pending_Download_Button
                        ) && (
                                <div className="">
                                    <Button
                                        name={"Download"}
                                        className=""
                                        style={{ maxHeight: "45px" }}
                                        onClick={() => handleDownload()}
                                    />
                                </div>
                            )}
                        {checkPermissionsInArray(AllPermissions.Product_Add) &&
                            typeValue == constant?.BRAND_CATEGORY[0]?.value && (
                                <div className="">
                                    <Button
                                        name={"Add Product"}
                                        style={{ minWidth: "140px", maxHeight: "45px" }}
                                        onClick={() =>
                                            navigate(`/products/add?add-type=${typeValue}`)
                                        }
                                    />
                                </div>
                            )}
                    </div>
                </div>
            </div>

            <div>
                <Table
                    columns={isContactLensProduct ? columnsForContactLens : columns}
                    AdditionalClassName="data-table-container"
                    data={tableData?.map((item: ProductDetail, index: number) => {
                        return {
                            ...item,
                            isActive: item.isActive ? (
                                <div className="background-light-green border-product-radius">
                                    <p className="color-green m-1 p-1">
                                        {constant.STATUS.ACTIVE}
                                    </p>
                                </div>
                            ) : (
                                <div className="background-light-red border-product-radius">
                                    <p className="color-red m-1 p-1">
                                        {constant.STATUS.DEACTIVE}
                                    </p>
                                </div>
                            ),
                            verification_status:
                                item.verification_status ===
                                    constant.VERIFICATION_STATUS.PENDING ? (
                                    <div className="background-light-yellow border-product-radius">
                                        <p className="color-yellow m-1 p-1">{"Pending"}</p>
                                    </div>
                                ) : item.verification_status ===
                                    constant.VERIFICATION_STATUS.VERIFIED ? (
                                    <div className="background-light-green border-product-radius">
                                        <p className="color-green m-1 p-1">{"Verified"}</p>
                                    </div>
                                ) : item.verification_status ===
                                    constant.VERIFICATION_STATUS.REWORK ? (
                                    <div className="background-light-red border-product-radius">
                                        <p className="color-red m-1 p-1">{"Rejected"}</p>
                                    </div>
                                ) : (
                                    ""
                                ),
                            action: (
                                <>
                                    {" "}
                                    <div className="table-data-width">
                                        <button
                                            className="btn dropdown-dots shadow-none action-button"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <Image src={ThreeDots} />
                                        </button>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end dropdown-style"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            <li>
                                                {/* {item.verification_status === constant.VERIFICATION_STATUS.VERIFIED ? ( */}
                                                <button
                                                    className="dropdown-item dropdown-item-size"
                                                    onClick={() => {
                                                        getDetailById(item.id);
                                                        setClickedBrandCategory(
                                                            item?.brand?.brand_category
                                                        );
                                                        setShowSidebarModal(true);
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <AiOutlineEye />
                                                        <span className="ms-2 mb-0">View</span>
                                                    </div>
                                                </button>
                                            </li>

                                            {/* ) : ( */}
                                            {item?.brand?.brand_category ===
                                                constant.BRAND_CATEGORY[0].value && (
                                                    <>
                                                        <li>
                                                            <hr className="my-1"></hr>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="dropdown-item dropdown-item-size"
                                                                onClick={() =>
                                                                    navigate(`/products/edit/${item.id}`)
                                                                }
                                                            >
                                                                <div className="d-flex align-items-center">
                                                                    <FiEdit />
                                                                    <span className="ms-2 mb-0">Edit</span>
                                                                </div>
                                                            </button>
                                                        </li>
                                                    </>
                                                )}

                                            {/* ) : ( */}
                                            {typeValue == constant.BRAND_CATEGORY[0].value && (
                                                <>
                                                    <li>
                                                        <hr className="my-1"></hr>
                                                    </li>
                                                    <li>
                                                        <button
                                                            className="dropdown-item dropdown-item-size"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/products/edit/${item.id
                                                                    }?isDuplicate=${true}`
                                                                )
                                                            }
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <FiEdit />
                                                                <span className="ms-2 mb-0">Duplicate</span>
                                                            </div>
                                                        </button>
                                                        {/* )} */}
                                                    </li>
                                                </>
                                            )}

                                            {/* ) : ( */}
                                            <li>
                                                <hr className="my-1"></hr>
                                            </li>
                                            <li>
                                                <button
                                                    className="dropdown-item dropdown-item-size"
                                                    onClick={() => handelDeleteButton(item?.id)}
                                                >
                                                    <div className="d-flex align-items-center text-danger">
                                                        <RiDeleteBin6Line />
                                                        <span className="ms-2 mb-0">Delete</span>
                                                    </div>
                                                </button>
                                                {/* )} */}
                                            </li>

                                            {/* ) : ( */}
                                            <li>
                                                <hr className="my-1"></hr>
                                            </li>
                                            <li>
                                                <button
                                                    className="dropdown-item dropdown-item-size "
                                                    onClick={() => {
                                                        setToggleProductStatus(!toggleProductStatus);
                                                        setProductID(item.id);
                                                    }}
                                                >
                                                    <div className={`d-flex align-items-center ${item.isActive ? 'text-danger' : ''}`}>
                                                        {!item.isActive ? <RiCheckboxCircleLine /> : <RiCloseCircleLine />}
                                                        <span className="ms-2 mb-0">{!item.isActive ? constant.STATUS.ACTIVE : constant.STATUS.DEACTIVE}</span>
                                                    </div>
                                                </button>
                                                {/* )} */}
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            ),
                        };
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={productResponse?.count}
                    currentPage={currentPage}
                    totalPages={productResponse?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => { setCurrentPage(1); setLimitPerPage(e) }}
                    limit={limit}
                />
            </div>
            {showSidebarModal && (
                <div>
                    <SidePopUp
                        show={showSidebarModal}
                        onHide={() => setShowSidebarModal(false)}
                        productDetail={productDetail}
                        showImage={(imageUrl: any, index: any) =>
                            showImage(imageUrl, index)
                        }
                        updateVerificationStatus={(id: string, status: number) =>
                            updateVerificationStatus(id, status)
                        }
                        isBrandedCategory={
                            clickedBrandCategory === constant.BRAND_CATEGORY[0].value
                        }
                    />
                </div>
            )}
            {showImageLarge && (
                <ImagePopup
                    imageUrlData={imageUrl}
                    handleClose={closeImage}
                    Index={imageIndex}
                />
            )}
            {deletePopup && (
                <ConfirmationModal
                    show={deletePopup}
                    handleConfirmItem={() => {
                        handleProductDelete(subAdminId);
                        setDeletePopup(!deletePopup);
                    }}
                    onHide={() => setDeletePopup(!deletePopup)}
                    message={"delete this product"}
                />
            )}
            {toggleProductStatus && (
                <ConfirmationModal
                    show={toggleProductStatus}
                    handleConfirmItem={() => {
                        toggleProductActivation && toggleProductActivation();
                        setToggleProductStatus(!toggleProductStatus);
                    }}
                    onHide={() => {
                        setToggleProductStatus(!toggleProductStatus);
                        setProductID && setProductID('');
                    }}
                    message={"change this product status"}
                />
            )}
        </>
    );
};
export default Index;
