import React, { useContext, useEffect, useState } from "react";
import organisms from "../../../components/organisms";
import { HeaderHeading } from "../../../Context";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import { LensDataProps, ProductTypeProps } from "./interface";
import molecules from "../../../components/molecules";
import { handlePriceChange } from "./hooks/useHelper";
import { useApiCalling } from "./hooks/useApiCall";

const { LensVariety } = organisms;
const { ConfirmationModal } = molecules;

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [productType, setProductType] = useState<ProductTypeProps[]>([]);
    const [lensTableData, setLensTableData] = useState<LensDataProps[]>();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    
    const { handleSubmit, getLensVarietyData, getProductTypeData } = useApiCalling()

    useEffect(() => {
        setHeader("E-commerce- Lens Variety");
        setSidebar(false);
        getProductTypeData(setProductType);
    }, []);

    return (
        <Wrapper>
            <LensVariety
                productType={productType}
                getLensVarietyData={(id: string) => getLensVarietyData({ id: id, setLensTableData: (data: LensDataProps[]) => setLensTableData(data) })}
                lensTableData={lensTableData}
                handleSubmit={() => setShowPopup(!showPopup)}
                handlePriceChange={(e, lensId, varietyId, addonId) => handlePriceChange(
                    {
                        e: e,
                        lensId: lensId,
                        varietyId: varietyId,
                        addonId: addonId,
                        lensTableData: lensTableData,
                        setLensTableData: setLensTableData
                    }
                )}
            />
            {showPopup && (
                <ConfirmationModal
                    show={showPopup}
                    handleConfirmItem={() => handleSubmit({
                        setShowPopup: setShowPopup,
                        showPopup: showPopup,
                        lensTableData: lensTableData
                    })}
                    onHide={() => setShowPopup(!showPopup)}
                    message={"save the changes"}
                />
            )}
        </Wrapper>
    );
};

export default Index;
