import React, { useState, useEffect, useContext, useRef } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import { useToasts } from 'react-toast-notifications';
import service from '../../../../api/services'
import { HeaderHeading } from '../../../../Context'
import { BarcodeDataProps } from './interface';

const { InventoryAudit } = organisms


const Index = () => {
    const [barcodeData, setBarcodeData] = useState<BarcodeDataProps | null>(null)
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [updateData, setUpdateData] = useState<any>({
        item_condition: [],
        conditionRemark: "",
    })
    const { addToast } = useToasts();
    const searchTabContainer = useRef<HTMLDivElement>(null);

    const handleBarcodeSearch = async (search: string) => {
        try {
            const response = await service.InventoryAuditService.barcodeSearchApi({ search: search })
            if (response.status === 200) {
                setBarcodeData(response?.data?.data)
                setUpdateData({ ...updateData, item_condition: {}, conditionRemark: "" })
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });

        }
    }

    const updateItemCondition = async (id: string) => {
        const payload = {
            "item_condition": updateData.item_condition.value,
            "condition_remark": updateData.conditionRemark
        }
        try {
            const response = await service.InventoryAuditService.updateItemCondition(id, payload)
            if (response.status === 200) {
                // handleBarcodeSearch(searchValue)
                setBarcodeData(null)
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                if (searchTabContainer?.current) {
                    const inputElement = searchTabContainer.current.querySelector('input');
              if (inputElement) {
                  (inputElement as HTMLInputElement).value = '';
              }
              }
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setUpdateData({ ...updateData, [name]: value })
    }

    useEffect(() => {
        setHeader("Inventory - Inventory Audit")
        setSidebar(false)
    }, [])

    return (
        <Wrapper>
            <InventoryAudit
                handleBarcodeSearch={(search: string) => handleBarcodeSearch(search)}
                updateItemCondition={updateItemCondition}
                barcodeData={barcodeData}
                handleChange={handleChange}
                updateData={updateData}
                searchTabContainer={searchTabContainer}
            />
        </Wrapper>
    )
}

export default Index