import React, { useState } from "react";
import organisms from "../../../../../components/organisms";
import service from "../../../../../api/services";
import Wrapper from "../../../../../components/common/Wrapper/Wrapper";
import { CouponDataProps, ValidationRules } from "../interface";
import { validationCouponRules } from "./validation";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router";

const { CreateCoupon } = organisms;

const Index = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [couponData, setCouponData] = useState<CouponDataProps>({
    coupon_name: '',
    code: "",
    discount_type: { label: "", value: NaN },
    max_usage: NaN,
    description: "",
    discount_value: NaN,
    start_date: "",
    end_date: "",
    discount_sub_type: { label: "", value: NaN },
    max_discount: NaN,
    min_order_value: NaN,
  });
  const handleCreateCoupon = async () => {
    const payload = {
      name: couponData.coupon_name,
      code: couponData.code,
      max_usage: couponData.max_usage,
      description: couponData.description,
      discount_value: couponData.discount_value,
      start_date: couponData.start_date,
      end_date: couponData.end_date,
      discount_type: couponData.discount_sub_type.value,
      coupon_type: couponData?.discount_type.value,
      max_discount: couponData.max_discount,
      min_order_value: couponData.min_order_value,
      is_active: false,
    };
    try {
      let response = await service.eCommerceService.couponCreate(payload);
      if (response.status === 200 || response.status === 201) {
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        navigate(`/e-commerce/coupon?isAcitve=true`)
      }
    } catch (error: any) {
      console.error(error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  };

  const handleChange = (
    name: string,
    value: string | number | { name: string; value: number }
  ) => {
    setCouponData({ ...couponData, [name]: value });
  };

  const couponValidation = (): boolean => {
    const newErrors: Record<string, string> = {};
    const rules: ValidationRules = validationCouponRules;

    Object?.keys(rules)?.forEach((field) => {
      const rule = rules[field];
      const fieldValue = couponData[field as keyof CouponDataProps];

      if (rule.required && !fieldValue) {
        newErrors[field] = "This field is required.";
      } else if (rule.label?.required && typeof fieldValue === "object" && !fieldValue?.label) {
        newErrors[field] = "Please select a value.";
      } else if (rule?.minLength && (fieldValue as string)?.length < (rule?.minLength as number)) {
        newErrors[field] = ` The minimum required length is ${rule?.minLength} characters.`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    let validate = couponValidation()
    if (validate) {
      handleCreateCoupon()
    }
  }

  return (
    <Wrapper>
      <CreateCoupon
        handleSubmit={handleSubmit}
        handleChange={(name: string, value: any) => handleChange(name, value)}
        couponData={couponData}
        errors={errors}
      />
    </Wrapper>
  );
};

export default Index;
