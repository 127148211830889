import React, { useState } from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
import { InventoryAuditProps } from './interface'
import constant from '../../../../constants/constant'

const { Input, Select, CustomSearch, Button } = atoms
const { InventoryAudit } = molecules

const Index = ({ handleBarcodeSearch, updateItemCondition, barcodeData, handleChange, updateData,searchTabContainer }: InventoryAuditProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const searchCallBack = (search: string) => {
        handleBarcodeSearch(search)
    }


    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-8 mt-0'>
                    <div ref={searchTabContainer}>
                        <div className=" position-relative" onClick={() => setIsOpen(!isOpen)} >
                            <CustomSearch
                                setIsOpen={setIsOpen}
                                searchCallBack={searchCallBack}
                                placeholder='Enter Barcode Number'
                                className='fs-16'
                                heading="Search"
                                buttonStyle="w-25" />
                        </div>
                    </div>
                </div>
                {/* {barcodeData?.audit_trails[0]?.createdAt && <div className='col-md-4 d-flex justify-content-end'>
                    <div className="d-flex flex-wrap align-items-center gap-2">
                        <h2 className='mb-0 fs-18 fw-600'>Updated Date :</h2>
                        <span className='fs-18 fw-400'>{barcodeData?.audit_trails?.length > 0 ? moment(barcodeData?.audit_trails[0]?.createdAt)?.format("DD/MM/YYYY") : ""}</span>
                    </div>
                </div>} */}
            </div>
            {barcodeData ? <>
                <div className='my-3'>
                    <InventoryAudit barcodeData={barcodeData} />
                </div>
                <div style={{ marginTop: "2rem" }}>
                    <div className='my-3'>
                        <div className='col-4'>
                            <label className={`inputs-label mb-2 fs-16 fw-600`}>Condition of the items</label>
                            <Select
                                options={constant.INVENTORY_ITEM_CONDITION}
                                name={"item_condition"}
                                value={updateData?.item_condition}
                                className={"w-100"}
                                placeholder={"Select Condition"}
                                onChange={(e: any) => { handleChange("item_condition", e) }}
                            />
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className='col-12'>
                            <label className={`inputs-label mb-2 fs-16 fw-600`}>Product Condition Remark</label>
                            <Input
                                type={'text'}
                                name={"conditionRemark"}
                                value={updateData.conditionRemark}
                                placeholder={"Enter Description Here"}
                                onChange={(e: any) => { handleChange("conditionRemark", e.target.value) }} />
                        </div>
                    </div>
                </div>
                <div className='mx-1 mt-20'>
                    <Button name={"Save"} className={"w-100"} onClick={() => { updateItemCondition(barcodeData?.id) }} />
                </div>
            </> :
                <div className='mt-3 d-flex justify-content-center dark-gray'>
                    <span>There are no records to display</span>
                </div>}
        </>
    )
}

export default Index