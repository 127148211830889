import { useToasts } from "react-toast-notifications";
import { ApiResult, ExtendedQueryError, GetLensVarietyDataProps, HandleSubmitProps, ProductTypeProps } from "../interface";
import service from "../../../../api/services";
import { getProductTypeDropdown } from "./useCreateDropdown";

export const useApiCalling = () => {
    const { addToast } = useToasts();

    const handleApiSubmit = async (id: string, data: {}) => {
        let payload = {
            lens_variety: data,
        };
        try {
            let response = await service.eCommerceService.updatedLensVarietyData(
                id,
                payload
            );
            if (response.status === 200) {
            }
        } catch (error) {
            console.error(error);
            const apiError = error as ExtendedQueryError;
            addToast(apiError?.response?.data?.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    };


    const handleSubmit = async ({ setShowPopup, showPopup, lensTableData
     }: HandleSubmitProps) => {
        setShowPopup(!showPopup);
        if (lensTableData) {
            const promises = lensTableData.map((lens) => {
                return handleApiSubmit(lens.id, lens.lens_variety);
            });

            Promise.allSettled(promises)
                .then((results) => {
                    const typedResults = results as PromiseSettledResult<ApiResult>[];

                    const hasError = typedResults?.some((result) => result.status === "rejected");

                    if (!hasError) {
                        addToast("Lens variety and price updated successfully.", {
                            appearance: "success",
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                    }
                })

        }
    };


    const getProductTypeData = async (setProductType: (data: ProductTypeProps[]) => void) => {
        try {
            let productTypeResponse =
                await service.productData.productTypeListingService({ search: "" });
            if (productTypeResponse.status === 200) {
                setProductType(getProductTypeDropdown(productTypeResponse.data.data));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getLensVarietyData = async ({ id, setLensTableData }: GetLensVarietyDataProps) => {
        try {
            let response = await service.eCommerceService.getLensVarietyData(id);
            if (response.status === 200) {
                setLensTableData(response?.data?.data);
            }
        } catch (error) {
            console.error(error);
            const apiError = error as ExtendedQueryError;
            addToast(apiError?.response?.data?.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    };

    return {
        handleSubmit,
        handleApiSubmit,
        getLensVarietyData,
        getProductTypeData,
    }
}
