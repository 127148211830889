import React from "react";
import atoms from "../../atoms";
import molecules from "../../molecules";
import { LensVarietyProps, OptionType } from "./interface";
import { SingleValue } from "react-select";

const { Select,Button } = atoms
const { LensTable } = molecules

const Index = ({ productType, getLensVarietyData, lensTableData, handleSubmit, handlePriceChange }: LensVarietyProps) => {
    return (
        <>
            <div className="row">
                <div className="col-4">
                <label className='inputs-label mb-2 fs-16 fw-800 secondary-color'>Product Type</label>
                    <Select
                        options={productType}
                        className={"w-100 product_multi_select"}
                        placeholder={"Select Type"}
                        onChange={(e: SingleValue<OptionType>) => {
                            if (e) {
                                getLensVarietyData(e.value);
                            }
                        }}
                    />
                </div>
                <div className="col-8"></div>
            </div>
            <div className="mt-2">
                {lensTableData && <LensTable
                    data={lensTableData}
                    handlePriceChange={handlePriceChange}
                />}
              {lensTableData &&  <div className="d-flex justify-content-center mt-3">
                    <Button onClick={handleSubmit}>Save Changes</Button>
                </div>}
            </div>

        </>
    )
}

export default Index