import React from "react";
import atoms from "../../../atoms";
import constant from "../../../../constants/constant";
import { EComProps, OptionType } from "./interface";
import { SingleValue } from "react-select";

const { Select, Input, Button } = atoms;

const Index = ({ handleChange, handleSubmit, reportData }: EComProps) => {
    const buttonDisable = (startDate: Date | null, endDate: Date | null) => {

        if (!startDate || !endDate) return true

        const parsedDate1 = new Date(startDate);
        const parsedDate2 = new Date(endDate);

        if (!parsedDate1 || isNaN(parsedDate1.getTime())) {
            return true;
        }
        if (!parsedDate2 || isNaN(parsedDate2.getTime())) {
            return true;
        }

        if (!reportData?.report_format) {
            return true;
        }
        return false;
    };
    return (
        <>
            <div className="row">
                <div className="col-4">
                    <Input
                        label={"Start Date"}
                        placeholder={"Start Date"}
                        type="date"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange("created_from", String(e));
                        }}
                    />
                </div>
                <div className="col-4">
                    <Input
                        label={"End Date"}
                        placeholder={"Enter Invoice Date"}
                        type="date"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange("created_to", String(e));
                        }}
                    />
                </div>
                <div className="col-4">
                    <Select
                        className="w-100"
                        options={constant.DailyReportFormatExcel}
                        onChange={(e: SingleValue<OptionType>) => e && handleChange("report_format", e.value)}
                        placeholder={"Select"}
                        label={"Select Report Format"}
                    />
                </div>
            </div>
            <div
                className="d-flex justify-content-center"
                style={{ marginTop: "10rem" }}
            >
                <Button
                    isDisabled={buttonDisable(
                        reportData?.created_from,
                        reportData?.created_to
                    )}
                    style={{ minWidth: "259px" }}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </Button>
            </div>
        </>
    );
};

export default Index;
