
export const PlaceHolderImage = 'https://images.pexels.com/photos/1054777/pexels-photo-1054777.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
export const LoginImage = 'https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/16905012/2023/7/26/1f947a56-e8f6-4893-9848-d79b196823b31690350140172-Voyage-Unisex-Black-Lens--Silver-Toned-Round-Sunglasses-with-1.jpg'

export const BASE_URL = process.env.REACT_APP_API_URL   
// export const BASE_URL = 'http://192.168.1.184:6001/api/'
// export const BULKUPLOAD_SAMPLE = process.env.REACT_APP_BULKUPLOAD_SAMPLE
export const BULKUPLOAD_SAMPLE = "https://specxweb-media-public-dev.s3.us-east-2.amazonaws.com/sample/sample_1728309506903.xlsx"


export const Default_Page_Limit = 20

export const Default_Page_Number=1
export const Contact_lens = 'contact lens'
export const reading= 'reading'

export const Store_Legal_Status = [
    { value: 1, label: 'Propritorship' },
    { value: 2, label: 'Partnership' },
    { value: 3, label: 'Company' },
]

export const SUNGLASS = "sunglass";
export const FRAME = "frame";

export const limitOptions = [ 20, 50,100,200];