const eCommerce = {
    createCoupon: () => "admin/coupon",
    listCoupon: () => "admin/coupon-list",
    updatedCoupon: (id:string) => `admin/coupon/${id}`,
    detailCoupon: (id:string) => `admin/coupon/${id}`,
    deleteCoupon: (id:string) => `admin/coupon/${id}`,
    activeDeactivateCoupon: (id:string) => `admin/coupon/status/${id}`,

    // for Lens Variety
    lensVariety: (id:string) => `admin/e-commerce/lens/${id}`,
    updateLensVariety:(id:string)=>`admin/e-commerce/lens/${id}`

}

export default eCommerce