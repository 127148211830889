import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import organisms from '../../../../components/organisms';
import { BrandData,ProductType,BrandPayload } from './interface';
import { HeaderHeading } from '../../../../Context'
const { AddBrandAllocation } = organisms
const AddBrand = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [brandList, setBrandList] = useState<BrandData | any>([])
    const [selectedBrand, setSelectedBrand] = useState([])
    const [buttonClick, setButtonClick] = useState(false)
    const [search, setSearch] = useState('');
    const storeId = params.id || ''
    const { setHeader,setSidebar }: any = useContext(HeaderHeading);
    const getBrandData = async () => {
        try {
            let response = await service.allocation.getBrandDetailById(storeId)
            if (response.status === 200) {
                setBrandList(response?.data?.data)
            }
        } catch (error) {

        }
    }

    const getPayload = () => {
        let brandData: any = []
        selectedBrand && selectedBrand.map((item: BrandData) => {
            let productType: any = []
            if (item.product_types) {
                item.product_types.map((data: ProductType) => {
                    productType.push({
                        product_type_name: data.product_type.name,
                        brand_margin: Number(data?.brand_margin),
                        brand_discount: Number(data?.brand_discount),
                        brand_landing_cost: Number(data?.brand_landing_cost),
                        product_type_id: data?.product_type.id,
                        specx_landing_cost: productType?.specx_landing_cost ?? 0,
                    })
                })
            }
            brandData.push({
                brand_id: item.id,
                product_types: productType
            })
        }
        )
        submitBrand(brandData)
    }
    const submitBrand = async (brand: BrandPayload) => {
        let payload = {
            brands: brand,
            stores: [storeId]
        }
        try {
            let response = await service.allocation.add(payload)
            if (response.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate("/store/brand/allocation-store")
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        setSidebar(true)
        setHeader("Store - Brand Allocation")
        getBrandData()
    }, [])
    useEffect(() => {
        if (buttonClick) {
            getPayload()
        }
        setButtonClick(false)
    }, [buttonClick])
    return (
        <Wrapper>
            <AddBrandAllocation brandList={brandList} setBrandList={setBrandList} search={search}
                setSearch={setSearch}
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                setButtonClick={setButtonClick}
            />
        </Wrapper>
    )
}
export default AddBrand