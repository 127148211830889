import { HandlePriceChangeProps } from "../interface";

export const handlePriceChange = ({ e, lensId, varietyId, addonId, lensTableData, setLensTableData }: HandlePriceChangeProps
) => {
    if (lensTableData) {
        const updatedData = [...lensTableData];
        const lens = updatedData?.find((lens) => lens.id === lensId);
        const variety = lens?.lens_variety.find(
            (variety) => variety?.id === varietyId
        );

        if (addonId) {
            const addon = variety?.AddOns.find((addon) => addon.id === addonId);
            if (addon) {
                addon.price = parseFloat(e.target.value);
            }
        } else {
            if (variety) {
                variety.price = parseFloat(e.target.value);
            }
        }

        setLensTableData(updatedData);
    }
};