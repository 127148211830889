import React from 'react';
import './lensTable.scss'
import { LensTableProps } from './interface';
import { findConstantLabel } from '../../../constants/utils';
import constant from '../../../constants/constant';
import atoms from '../../atoms';

const { Input } = atoms

const Index = ({ data, handlePriceChange }: LensTableProps) => {

    return (
        <div>
            <div className='lens_table_custom_table table-responsive'>
                <table className=' w-100 position-relative '>
                    <thead className='table-thead background-blue color-black position-sticky sticky-top '>
                        <tr>
                            <th>Lens Type</th>
                            <th>Lens Variety</th>
                            <th>Add-ons</th>
                        </tr>
                    </thead>
                    <tbody className='mt-3 lens_table_table-tbody'>
                        {(data && data?.length > 0) && data.map(lens => (
                            lens.lens_variety.map((variety, index) => (
                                <tr className='p-1 lens_table_border-top ' key={variety.id}>
                                    {index === 0 && (
                                        <td className='border-right lens_table_first_column'
                                            rowSpan={lens.lens_variety.length}>
                                            {findConstantLabel(constant?.LENS_SUB_TYPE, lens.lens_type)}
                                        </td>
                                    )}
                                    <td className='border-right p-1'>
                                        <div className='row px-2 align-items-center'>
                                            <div className='col-9'>
                                                <strong>{variety.title}</strong>
                                            </div>
                                            <div className='col-3 text-end'>
                                                <Input
                                                    type="number"
                                                    value={variety.price}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePriceChange(e, lens.id, variety.id)}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td className=''>
                                        <div className='w-100' style={{ overflow: "hidden" }}>
                                            {variety?.AddOns?.map((addon, index) => {
                                                let appliedCss = index % 2 !== 0
                                                return (
                                                    <div key={addon?.id} className={`row pb-2 align-items-center ${appliedCss ? "lens_table_background_color_primary" : "lens_table_background_color_secondary"}`}>
                                                        <div className='col-9'>{addon?.title}</div>
                                                        <div className='col-3 pt-1'>
                                                            <Input
                                                                type="number"
                                                                value={addon.price}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePriceChange(e, lens.id, variety.id, addon.id)}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Index;
