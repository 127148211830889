import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import service from '../../../../api/services'
import { useToasts } from 'react-toast-notifications';
import { HeaderHeading } from '../../../../Context'
import { getDropdown, getParams } from '../../../../constants/utils'
import { Default_Page_Limit, Default_Page_Number } from '../../../../constants/defaultValues'
import { useSearchParams } from 'react-router-dom';
import constant from '../../../../constants/constant';

const { InventoryQuantity } = organisms
const Index = () => {
  const { addToast } = useToasts();
  const [searchParams] = useSearchParams();
  const [typeValue, setTypeValue] = useState<any>(getParams('quantity-type'))
  const [brandListing, setBrandListing] = useState<any>();
  const [storeData, setStoreData] = useState<any>()
  const [listingData, setListingData] = useState<any>([]);
  const [pageCount, setPageCount] = useState<number>(Default_Page_Number);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const [isOpen, setIsOpen] = useState<Boolean>(true);
  const [noDataText, setNoDataText] = useState<Boolean>(false);
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const [range, setRange] = React.useState({
    minPrice: null,
    maxPrice: null
  });
  const [searchData, setSearchData] = useState({
    product_id: "",
    modal_number: "",
    brand: [],
    store: [],
    price: "",
  });
  useEffect(() => {
    setTypeValue(getParams('quantity-type'))
    setBrandListing([])
    setStoreData([])
    setListingData({})
    setPageCount(Default_Page_Number)
    setLimit(Default_Page_Limit)
}, [searchParams])
  useEffect(() => {
    setPageCount(1)
    if (pageCount == 1) {
      // getBrandListing()
    }
  }, [limit])
  const getBrandListing = async () => {
    try {
      const response = await service.inventory.brandListing({is_recognised: typeValue === `${constant.BRAND_CATEGORY[0].value}` ? true : false});
      const storeResponse = await service.inventory.storeView({brand_category: typeValue})
      if (response.status === 200) {
        setBrandListing(getDropdown(response.data.data, "brand_name"));
        setStoreData(getDropdown(storeResponse?.data?.data, 'store_location', 'store_name'))
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _handleSearch = async () => {
    let brandIDs = searchData?.brand?.map((item: any) => item.value);
    const storeIds = searchData?.store?.map((item: any) => item.value)
    const payload = {
      product_id: searchData.product_id.replace(/ +/g, ""),
      modal_number: searchData.modal_number.replace(/ +/g, ""),
      brand: brandIDs,
      store_ids: storeIds,
      price: {
        minPrice: range.minPrice,
        maxPrice: range.maxPrice,
      },
    };
    try {
      const response = await service.inventory.quantitySearch(payload)
      if (response.status === 200) {
        setListingData(response.data.data)
        setNoDataText(true)
        if(response?.data?.data.length>0){
          setIsOpen(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    setHeader("Inventory - Visible Min Quantity")
    getBrandListing()
    _handleSearch()
  }, [typeValue])

  const handlePriceRange = (event: any, newValue: any) => {
    setRange(newValue);
  }

  const handleInputPrice = (e: any) => {
    e.preventDefault();
    setRange(previousState => ({
      ...previousState, [e.target.name]: e.target.value
    }));
  }

  return (
    <Wrapper>
      <InventoryQuantity
        brandListing={brandListing}
        setSearchData={setSearchData}
        searchData={searchData}
        _handleSearch={() => _handleSearch()}
        listingData={listingData}
        range={range}
        handlePriceRange={handlePriceRange}
        handleInputPrice={handleInputPrice}
        limit={limit}
        currentPage={pageCount}
        setLimitPerPage={setLimit}
        setCurrentPage={setPageCount}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        noDataText={noDataText}
        storeData={storeData}
      />
    </Wrapper>
  )
}

export default Index